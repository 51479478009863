import React from "react";
import { Container } from "reactstrap";
import { Table, Card, CardBody, CardHeader, Input } from "reactstrap";
import { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
//import { toast } from 'react-toastify'
//import style from '../DatiSpoglio/style.module.scss'
import PaginationCustom from "./Pagination";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { approveUser, refuseUser, fetchAllUsers } from "../../helpers/fakebackend_helper";
import ModificaUser from "./ModificaUser";
import EliminaUser from "./EliminaUser";

const Rappresentanti = () => {
  //meta title
  document.title = "Visualizza Utenti | Gestione Rappresentanti di Lista";

  const [user, setUser] = useState(null);
  const [modal_modificaUser, setmodal_modificaUser] = useState(false)
  const [modal_eliminaUser, setmodal_eliminaUser] = useState(false)

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [filtroNome, setFiltroNome] = useState('')
  const [filtroCognome, setFiltroCognome] = useState('')
  const [filtroComune, setFiltroComune] = useState('')
  const [totPages, setTotPages] = useState(1)

  const token = JSON.parse(localStorage.getItem('token'))

  function tog_modificaUser(user) {
    setUser(user)
    setmodal_modificaUser(!modal_modificaUser)
  }

  function tog_eliminaUser(user) {
    setUser(user)
    setmodal_eliminaUser(!modal_eliminaUser)
  }


  function handleFilters(e) {
    setPage(1)
    switch (e.target.attributes.name.value) {
      case "firstName":
        setFiltroNome(e.target.value)
        break
      case "lastName":
        setFiltroCognome(e.target.value)
        break
      case "comune":
        setFiltroComune(e.target.value)
        break
      default:
        break
    }
  }

  useEffect(() => {
    // parametri per il fetch
    const options = {};
    options.page = page;
    if (filtroNome) options.firstName = filtroNome;
    if (filtroCognome) options.lastName = filtroCognome;
    if (filtroComune) options.comune = filtroComune;

    let timeout = 1500
    if (!options.firstName && !options.lastName && !options.comune) {
      timeout = 0
    }
    const delayDebounceFn = setTimeout(() => {
      if (!modal_eliminaUser && !modal_modificaUser && token) {
        fetchAllUsers(options)
          .then((res) => {
            setUsers(res.users)
            setTotPages(res.pages)
          })
          .catch((err) => console.log(err));
      }
    }, timeout);

    return () => clearTimeout(delayDebounceFn);
  }, [filtroNome, filtroCognome, filtroComune, page, modal_eliminaUser, modal_modificaUser, token]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Utenti"
            breadcrumbItem="Visualizza Utenti Iscritti"
          />
          {/* LISTA DI TUTTI GLI UTENTI VISIBILE SOLO AL PARTITO CENTRALE - ACCESSO: PARITO CENTRALE */}

          {users.length < 1 ? <h3>Nessun utente trovato</h3> : (
            <>
              <Card>
                <div className="table-responsive">
                  <CardHeader>
                    <h4 className="card-title">Lista Utenti</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="align-middle mb-0">
                        <thead>
                          <tr>
                            <th>
                              <Input
                                type="text"
                                name="firstName"
                                placeholder="Filtra per nome"
                                onChange={(e) => handleFilters(e)}
                              />
                            </th>
                            <th>
                              <Input
                                type="text"
                                name="lastName"
                                placeholder="Filtra per cognome"
                                onChange={(e) => handleFilters(e)}
                              />
                            </th>
                            <th> </th>
                            <th> </th>
                            <th>
                            </th>
                            <th> </th>
                            <th> </th>
                            <th>
                              <Input
                                type="text"
                                name="comune"
                                placeholder="Filtra per comune"
                                onChange={(e) => handleFilters(e)}
                              /></th>
                          </tr>
                          <tr>
                            <th>Nome</th>
                            <th>Cognome</th>
                            <th>Ruolo</th>
                            <th>Telefono</th>
                            <th>Email</th>
                            <th>Regione</th>
                            <th>Provincia</th>
                            <th>Comune</th>
                            <th>Sezione</th>
                            <th>Modifica</th>
                            <th>Elimina</th>
                          </tr>
                        </thead>


                        {users &&
                          users.map((user) => (
                            <>
                              <tbody key={user._id}>
                                <tr>
                                  <td>{user.firstName} </td>
                                  <td>{user.lastName}</td>
                                  <td>{user.role}</td>
                                  {user.phone ? <td>{user.phone}</td> : <td>-</td>}
                                  <td>{user.email}</td>
                                  {user.regione ? <td>{user.regione}</td> : <td>-</td>}
                                  {user.provincia ? <td>{user.provincia}</td> : <td>-</td>}
                                  {user.comune ? <td>{user.comune}</td> : <td>-</td>}
                                  {(user.sezioni && user.sezioni.length > 0) ? <td>{user.sezioni.toString()}</td> : (<td>-</td>)}
                                  <td><button className="btn btn-outline-primary" onClick={() => tog_modificaUser(user)}><i className="mdi mdi-lead-pencil" /></button></td>
                                  <td><button className="btn btn-outline-danger" onClick={() => tog_eliminaUser(user)}><i className="mdi mdi-close-thick" /></button></td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                      </Table>

                      {user &&
                        <ModificaUser modal_modificaUser={modal_modificaUser} setmodal_modificaUser={setmodal_modificaUser} user={user} />
                      }

                      {user &&
                        <EliminaUser modal_eliminaUser={modal_eliminaUser} setmodal_eliminaUser={setmodal_eliminaUser} user={user} />}

                    </div>
                  </CardBody>
                </div>
              </Card>

              {/* Paginazione */}
              <PaginationCustom currentPage={page} setPage={setPage} totalPages={totPages} />
            </>

          )}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rappresentanti;

import React, { useState, useEffect } from "react"
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
} from "reactstrap"

import FeatherIcon from "feather-icons-react"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { resetProfileFlag } from "../../store/actions"
import { toast } from "react-toastify"

import { postNewPassword } from "../../helpers/fakebackend_helper"

const UserProfile = (props) => {
  const dispatch = useDispatch()

  const { error, success } = useSelector((state) => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [lastname, setlastname] = useState("")
  const [idx, setidx] = useState("")
  const [prov, setProv] = useState("")
  const [regione, setRegione] = useState("")
  const [comune, setComune] = useState("")
  const [sezioni, setSezioni] = useState([])
  const [phone, setPhone] = useState(null)

  useEffect(() => {
    const authUser = localStorage.getItem("user")
    if (authUser) {
      const obj = JSON.parse(authUser)
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.firstName)
        setlastname(obj.lastName)
        setemail(obj.email)
        setidx(obj.role)
        setProv(obj.provincia)
        setComune(obj.comune)
        setRegione(obj.regione)
        setSezioni(obj.sezioni)
        setPhone(obj.phone)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      repeatPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Devi inserire tutti i campi"),
      repeatPassword: Yup.string().required("Devi inserire tutti i campi"),
    }),
    onSubmit: (values) => {
      if (values.password !== values.repeatPassword)
        return toast.error("Le password non coincidono")
      postNewPassword(values)
        .then((res) => toast.success(res.message))
        .catch((err) => toast.error(err.response.data.message))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profilo" breadcrumbItem="Profilo utente" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <FeatherIcon icon="user" />
                    </div>
                    <div className="d-flex justify-content-between ms-3">
                      <div className="text-muted">
                        <h5>
                          {name} {lastname}{" "}
                        </h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">{idx}</p>
                        {phone ? <p className="mb-0">Telefono: {phone}</p> : <p className="mb-0">Telefono: - </p>}

                        <div className="mt-3">
                          <h5>Area:</h5>
                          {idx == 'Partito Centrale' && <p>Italia</p>}
                          {regione && <p className="mb-1">{regione}</p>}
                          {prov && <p className="mb-1">{prov}</p>}
                          {comune && <p className="mb-1">{comune}</p>}
                          {(sezioni && sezioni.length > 0) && <p className="mb-1">Sezione: {sezioni.toString()}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <FeatherIcon icon="lock" />
                    </div>
                    <div className="d-flex justify-content-between ms-3">
                      <div className="text-muted">
                        <h5>Cambia password</h5>
                      </div>
                    </div>
                  </div>
                  <div className="ms-3">
                    <div className="ms-3">
                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            id="password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Ripeti password</Label>
                          <Input
                            id="repeatPassword"
                            type="password"
                            name="repeatPassword"
                            placeholder="Ripeti password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.repeatPassword || ""}
                          />
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={
                              !validation.values.password ||
                              !validation.values.repeatPassword
                            }>
                            Cambia Password
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile

import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getComuni, getProvince, getRegioni, getSezioni, ModificaUtente } from "../../helpers/fakebackend_helper";

const ModificaUser = ({ modal_modificaUser, setmodal_modificaUser, user }) => {
  const [sezioni, setSezioni] = useState(null);
  const [selectedSezioni, setSelectedSezioni] = useState([]);
  //const [selezionato, setselezionato] = useState([]);

  const [regioni, setRegioni] = useState(null)
  const [province, setProvince] = useState(null)
  const [comuni, setComuni] = useState(null)

  const [regione, setRegione] = useState('')
  const [provincia, setProvincia] = useState('')
  const [comune, setComune] = useState('')

  const [ruoloSelezionato, setruoloSelezionato] = useState()

  function tog_modificaUser() {
    setmodal_modificaUser(!modal_modificaUser);
  }

  function modifica() {
    // test
    let data = {
      userId: user._id,
      role: ruoloSelezionato,
      regione: regione,
      provincia: provincia,
      comune: comune
    }
    if (data.role === 'Presidente Regionale') {
      data.provincia = ''
      data.comune = ''
      data.sezioni = []
    }
    if (data.role === 'Coordinatore Provinciale') {
      data.comune = ''
      data.sezioni = []
    }
    if (data.role === 'Coordinatore Comunale') {
      data.sezioni = []
    }
    if (data.role === 'Rappresentante di Lista') {
      if ( selectedSezioni.length != 0){
        console.log(selectedSezioni.map((item) => parseInt(item.value)))
        data.sezioni =  selectedSezioni.map((item) => parseInt(item.value));
      }
      
    }

    ModificaUtente(data)
      .then((res) => {
        toast.success(res.message)
        tog_modificaUser()
      })
      .catch((err) => toast.error(err.message));
  }

  const handleFetchRegioni = () => {
    getRegioni({ params: { role: user.role } })
      .then((res) => {
        if (user.role === 'Presidente Regionale') res.push(user.regione)
        setRegioni(res)
      })
      .catch((err) => toast.error(err.message))
  }

  const handleFetchProvince = (regione) => {
    getProvince({ params: { regione, role: user.role } })
      .then((res) => {
        setProvince(res)
      })
      .catch((err) => toast.error(err.message))
  }

  const handleFetchComuni = (provincia) => {
    getComuni({ params: { provincia, role: user.role } })
      .then((res) => {
        setComuni(res)
      })
      .catch((err) => toast.error(err.message))
  }

  const handleFetchSezioni = () => {
    getSezioni({ params: { comune: comune, role: ruoloSelezionato } })
      .then((res) => {

        // includo le sezioni dell'utente corrente all'interno delle options
        let completeOptions
        if (Array.isArray(res)) {
          completeOptions = res.concat(user.sezioni)
        } else {
          completeOptions = res.push(user.sezioni)
        }

        const options = completeOptions.sort((a, b) => a - b).map((sez) => {
          return { value: sez, label: sez };
        });
        const selectedOptions = user.sezioni.sort((a, b) => a - b).map((sez) => {
          return { value: sez, label: sez };
        });

        setSelectedSezioni(() => selectedOptions);
        setSezioni(() => options);
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    handleFetchRegioni()
  }, [])

  useEffect(() => {
    setruoloSelezionato(user.role)
    setRegione(user.regione)
    setProvincia(user.provincia)
    setComune(user.comune)
    setSezioni(user.sezioni)

  }, [user])

  useEffect(() => {
  }, [ruoloSelezionato])

  useEffect(() => {
    if (regione) handleFetchProvince(regione)
  }, [regione])

  useEffect(() => {
    if (regione && provincia) handleFetchComuni(provincia)
  }, [provincia])

  useEffect(() => {
    if (regione && provincia && comune && ruoloSelezionato === 'Rappresentante di Lista') {
      if (comune === user.comune || user.role !== 'Rappresentante di Lista') {
        handleFetchSezioni()
      } else {
        setSelectedSezioni([])
      }
    }
  }, [user, comune]);

  return (
    <Modal
      size="xl"
      isOpen={modal_modificaUser}
      toggle={() => {
        tog_modificaUser();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
          Modifica Utente:
        </h5>
        <button
          onClick={() => {
            setmodal_modificaUser(false);
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {/* role regioni prov comune sez */}

      <div className="modal-body">
        <h5>
          {user.firstName} {user.lastName}
        </h5>
        <div className="mt-4">
          <p> <b>Regione:</b> {user.regione}</p>
          {user.role !== 'Presidente Regionale' &&
            <p><b>Provincia:</b> {user.provincia || ''}</p>}
          {user.role === 'Coordinatore Comunale' || user.role === 'Rappresentante di Lista' &&
            <p><b>Comune:</b> {user.comune || ''}</p>
          }

          <h5>Contatti:</h5>
          <p>{user.email}</p>
          {user.phone && <p>{user.phone} </p>}
        </div>

        <div>

          {user?.role == 'Rappresentante di Lista' &&
            <h3>Modifica</h3>
          }

          <select key="role" id="role" className="form-select mb-3" name="role" defaultValue={user.role} onChange={(e) => setruoloSelezionato(e.target.value)}
          >
            {//user.role &&
              //<option value={user.role} label={user.role.toUpperCase()} key='def' > {user.role}</option>
            }
            <option value='Presidente Regionale' label='Presidente Regionale' key='Presidente Regionale' />
            <option value='Coordinatore Provinciale' selected label='Coordinatore Provinciale' key='Coordinatore Provinciale' />
            <option label='Coordinatore Comunale' value='Coordinatore Comunale' key='Coordinatore Comunale' />
            <option value='Rappresentante di Lista' label='Rappresentante di Lista' key='Rappresentante di Lista' />
          </select>


          <h5>Modifica Area:</h5>
          <select
            key="reg"
            id="regione"
            className="form-select mb-3"
            name="regione"
            value={regione}
            onChange={(e) => setRegione(e.target.value)}>

            <option
              value={''}
              label={"Seleziona una regione"}
              key="regionedef"
            ></option>
            {regioni &&
              regioni.map((region, i) => (
                <option
                  key={i}
                  name={region}
                  label={region.toUpperCase()}
                  value={region}>
                  {region}
                </option>
              ))}
          </select>

          {ruoloSelezionato !== 'Presidente Regionale' && (
            <select
              key="prov"
              id="provincia"
              className="form-select mb-3"
              name="provincia"
              value={provincia}
              onChange={(e) => setProvincia(e.target.value)}
              disabled={!regione}>
              <option
                value={''}
                label={"Seleziona una provincia"}
                key="provinciadef"
              ></option>
              {province &&
                province.map((provincia, i) => (
                  <option
                    key={i}
                    name={provincia}
                    label={provincia.toUpperCase()}
                    value={provincia}>
                    {provincia}
                  </option>
                ))}
            </select>
          )}

          {ruoloSelezionato !== 'Presidente Regionale' && ruoloSelezionato !== 'Coordinatore Provinciale' && (
            <select
              key="com"
              id="comune"
              className="form-select mb-3"
              name="comune"
              value={comune}
              onChange={(e) => setComune(e.target.value)}
              disabled={!provincia}
            >
              <option
                value={''}
                label={"Seleziona un comune"}
                key="comunedef"
              ></option>
              {comuni &&
                comuni.map((comune, i) => (
                  <option
                    key={i}
                    name={comune}
                    label={comune.toUpperCase()}
                    value={comune}
                  >
                    {comune}
                  </option>
                ))}
            </select>
          )}


          {
            ruoloSelezionato === "Rappresentante di Lista" && (
              <>
                <label className="form-Label">Sezione:</label>
                <Select
                  options={sezioni}
                  isMulti
                  value={selectedSezioni}
                  onChange={(inputValue) => setSelectedSezioni(inputValue)}
                  disabled={!comune}
                />
              </>
            )}
        </div>

      </div>

      <div className="modal-footer">
        <button
          type="button"
          onClick={tog_modificaUser}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Annulla
        </button>

        <button type="button" className="btn btn-primary" onClick={modifica}>
          Modifica
        </button>


      </div>
    </Modal>
  );
}


export default ModificaUser
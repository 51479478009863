import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { changeStatus, deleteIssue, getDetailsIssue } from "../../helpers/fakebackend_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container, Card, CardBody, Modal } from "reactstrap"
import FeatherIcon from "feather-icons-react"
import { toast } from 'react-toastify';


const DettagliSegnalazione = () => {
  const [issue, setIssue] = useState({})
  const [modal, setModal] = useState(false)
  const [modalFixed, setModalFixed] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)

  const { id } = useParams()
  let history = useHistory()


  //opzioni data
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  function tog_modal() {
    setModal(!modal)
  }

  function tog_modal_fixed() {
    setModalFixed(!modalFixed)
  }

  function tog_delete() {
    setModalDelete(!modalDelete)
  }

  const inCarico = () => {
    changeStatus(id, {status: 'in-charge'})
    .then((res) => {
      toast.success(res.message)
      setTimeout(()=> {
        history.push("/criticita")
      }, 3000)
      tog_modal()
    })
    .catch((err) => {
      console.log(err)
      toast.error(err.message)
      setTimeout(()=> {
        tog_modal()
      }, 1000)
     
    })
  }

  const resolve = () => {
    changeStatus(id, {status: 'fixed'})
    .then((res) => {
      toast.success(res.message)
      setTimeout(()=> {
        history.push("/criticita")
      }, 3000)
      tog_modal_fixed()
    })
    .catch((err) => {
      console.log(err)
      toast.error(err.message)
      setTimeout(()=> {
        tog_modal_fixed()
      }, 1000)
     
    })
  }

  const elimina = () => {
    deleteIssue(id)
      .then((res) => {
        toast.success(res.message)
        setTimeout(()=> {
          history.push("/criticita")
        }, 3000)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
        setTimeout(()=> {
          tog_delete()
        }, 1000)
       
      })
  }

  useEffect(() => {
    getDetailsIssue(id).then((response) => {
      setIssue(response)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Criticità"
            breadcrumbItem="Dettagli Segnalazione"
          />

          <Card className="border border-primary">
            <div className="card-header bg-transparent border-primary">
              <h5 className="my-0 text-primary">
                <i className="mdi mdi-newspaper-variant-outline me-3"></i>
                {issue.title}
              </h5>
            </div>
            <CardBody>
              {issue.subtitle && (
                <h5 className="card-title">{issue.subtitle}</h5>
              )}

              <p className="card-text">{issue.description}</p>

              {issue.attachments && (
                <a href={issue.attachments} download>
                  <b>Download file</b>
                  <FeatherIcon icon="download" style={{ marginLeft: "10px" }} />
                </a>
              )}

              {issue.updatedAt && (
                <p>
                  Ultimo aggiornamento: <span> </span>
                  {new Intl.DateTimeFormat("it-IT", options).format(
                    new Date(issue.updatedAt)
                  )}
                </p>
              )}

<div className="d-flex justify-content-between">
                    <div>
                    <button
                className="btn btn-primary mt-3 me-2"
                onClick={() => {
                  tog_modal()
                }}
                data-toggle="modal"
                data-target="#myModal">
                Prendi in carico
              </button>

              <button
                className="btn btn-primary mt-3 me-2"
                onClick={() => {
                  tog_modal_fixed()
                }}
                data-toggle="modalFixed"
                data-target="#myModal">
               Segna come risolta
              </button>
                    </div>
                    <div>
                    <button
                className="btn btn-danger mt-3"
                onClick={() => {
                  tog_delete()
                }}
                data-toggle="modal"
                data-target="#myModal">
                Elimina <i className="mdi mdi-delete-empty me-1"></i>
              </button>
                    </div>

              </div>      

              <Modal
                isOpen={modal}
                toggle={() => {
                  tog_modal()
                }}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Vuoi prendere in carico questa segnalazione?
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Clicca su <strong>Prendi in carico</strong> per segnare lo status di questa segnalazione come "presa in carico". <br />
                    Per annullare l'operazione, clicca su{" "}
                    <strong>Annulla</strong>.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      tog_modal()
                    }}
                    className="btn btn-primary "
                    data-dismiss="modal">
                    Annulla
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => inCarico(issue._id)}>
                    Prendi in carico  <i className="mdi mdi-wrench me-1"></i>
                  </button>
                </div>
              </Modal>

              <Modal
                isOpen={modalFixed}
                toggle={() => {
                  tog_modal_fixed()
                }}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Vuoi impostare questa segnalazione come risolta?
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModalFixed(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Clicca su <strong>Risolta</strong> per cambiare lo
                    status di questa segnalazione in "risolta". <br />
                    Per annullare l'operazione, clicca su{" "}
                    <strong>Annulla</strong>.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      tog_modal_fixed()
                    }}
                    className="btn btn-primary "
                    data-dismiss="modal">
                    Annulla
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                     onClick={() => resolve(issue._id)}
                    >
                    Risolta  <i className="mdi mdi-checkbox-marked-outline me-1"></i>
                  </button>
                </div>
              </Modal>

              <Modal
                isOpen={modalDelete}
                toggle={() => {
                  tog_delete()
                }}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                  Vuoi eliminare questo elemento?
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModalDelete(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                  Clicca su <strong>Elimina</strong> per eliminare
                        definitivamente questa segnalazione. <br />
                        Per annullare l'operazione, clicca su{" "}
                        <strong>Annulla</strong>.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      tog_delete()
                    }}
                    className="btn btn-primary "
                    data-dismiss="modal">
                    Annulla
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => elimina(issue._id)}
                   >
                    Elimina
                  </button>
                </div>
              </Modal>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DettagliSegnalazione

import React from "react"
import { Container, Col, Form, Input, Label, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Formlayouts from "../Forms/FormElements/Formlayouts"
import BasicPills from "../Forms/FormWizard/BasicPills"

const Candidatura = () => {
  //meta title
  document.title = "Nuova Candidatura | Gestione Rappresentanti di Lista"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Rappresentanti"
            breadcrumbItem="Nuova Candidatura"
          />
          QUI C'è IL FORM E SI CANDIDANO - ACCESSO: TUTTI? <br/>
          SE LA 'CANDIDATURA' AVVIENE AL MOMENTO DELLA REGISTRAZIONE, QUESTA PAGINA VA ELIMINATA
          <BasicPills />
          {/* OPPURE */}
          {/* <Formlayouts/> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Candidatura

import React, { useState, useEffect } from "react"
import RegistrationForm from "../../components/Common/RegistrationForm"
import {
  getRegioni,
  getProvince,
  postSignup,
} from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify"

export default function RegisterCoordinatoreProvinciale() {
  const [regioni, setRegioni] = useState(null)
  const [province, setProvince] = useState(null)
  const [regione, setRegione] = useState("")
  const [provincia, setProvincia] = useState("")
  const role = "Coordinatore Provinciale"

  const handleOnSubmit = (inputsData) => {
    if (regione && provincia) {
      postSignup({ ...inputsData, role, regione, provincia })
        .then(() => window.location.replace("/page-register-verification"))
        .catch((err) => toast.error(err.response.data.message))
    }
  }
  const handleFetchRegioni = () => {
    getRegioni()
      .then((res) => setRegioni(res))
      .catch((err) => toast.error(err.message))
  }

  const handleFetchProvince = (regione) => {
    getProvince({ params: { regione, role } })
      .then((res) => setProvince(res))
      .catch((err) => toast.error(err.message))
  }

  useEffect(() => handleFetchRegioni(), [])
  useEffect(() => {
    if (regione) handleFetchProvince(regione)
  }, [regione])

  return (
    <>
      <RegistrationForm
        handleOnSubmit={handleOnSubmit}
        roleForm={
          <>
            <div className="mb-3" style={{ marginTop: "40px" }}>
              {/* <h6>Seleziona la tua area di appartenenza</h6> */}
              <select
                key="reg"
                id="regione"
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setRegione(e.target.value)}>
                <option value="">Seleziona la tua regione</option>
                {regioni &&
                  regioni.map((regione) => (
                    <option key={regione} value={regione}>
                      {regione}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-3">
              <select
                key="prov"
                id="provincia"
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setProvincia(e.target.value)}>
                <option value="">Seleziona la tua provincia</option>
                {province &&
                  province.map((provincia) => (
                    <option key={provincia} value={provincia}>
                      {provincia}
                    </option>
                  ))}
              </select>
            </div>
          </>
        }
      />
    </>
  )
}

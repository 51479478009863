import React, { useState, useEffect } from 'react'
import { Container, Table, Card, CardBody, CardHeader, Modal } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { approveUser, getUsersInPending, refuseUser } from '../../helpers/fakebackend_helper';
import { toast } from 'react-toastify';
import ModificaUser from './ModificaUser';
//import ModalSezioni from './ModalSezioni';

const UtentiInPending = () => {
  //meta title
  document.title = "Approva Utenti | Gestione Rappresentanti di Lista";

  const [users, setUsers] = useState(null);
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [refreshPage, setRefreshPage] = useState(false)

  const [user, setUser] = useState(null);

  const token = JSON.parse(localStorage.getItem('token'))

  function tog_xlarge(user) {
    setUser(user)
    setmodal_xlarge(!modal_xlarge)

  }

  useEffect(() => {
    if (token) {
      getUsersInPending()
      .then((res) => setUsers(res))
      .catch((err) => toast.error(err.message));
    }
  }, [refreshPage, modal_xlarge, token]);

  const approva = (approveId) => {
    approveUser({ userId: approveId })
      .then((res) => {
        toast.success(res.message)
        setRefreshPage(!refreshPage)
      })
      .catch((err) => toast.error(err.response.data.message))
  }

  const rifiuta = (approveId) => {
    refuseUser({ userId: approveId })
      .then((res) => {
        toast.success(res.message)
        setRefreshPage(!refreshPage)
      })
      .catch((err) => {
        toast.error(err.data.message)
        console.log(err)
      })
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Utenti"
          breadcrumbItem="Approva Utenti"
        />
        {/* LISTA UTENTI DA APPROVARE*/}

        {users && users.length != 0 &&
          <Card>
            <div className="table-responsive">
              <CardHeader>
                <h4 className="card-title">Lista Utenti</h4>
              </CardHeader>
              <CardBody>
                <div className="table-responsive">
                  <Table className="align-middle mb-0">
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>Ruolo</th>
                        <th>Email</th>
                        <th>Regione</th>
                        <th>Provincia</th>
                        <th>Comune</th>
                        <th>Sezione</th>
                        <th>Approva</th>
                        <th>Modifica</th>
                        <th>Rimuovi</th>
                      </tr>
                    </thead>

                    {/* MAPPARE GLI UTENTI IN PENDING */}

                    {users &&
                      users.map((user) => (
                        <>
                          <tbody key={user._id}>
                            <tr>
                              <td>{user.firstName} </td>
                              <td>{user.lastName}</td>
                              <td>{user.role}</td>
                              <td>{user.email}</td>
                              {user.regione ? <td>{user.regione}</td> : <td>-</td>}
                              {user.provincia ? <td>{user.provincia}</td> : <td>-</td>}
                              {user.comune ? <td>{user.comune}</td> : <td>-</td>}
                              {(user.sezioni && user.sezioni.length > 0) ? <td>{user.sezioni.toString()}</td> : (<td>-</td>)}

                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={() => approva(user._id)}
                                >
                                  <i className="bx bx-check-square"></i>
                                </button>
                              </td>
                              <td>
                                <button type="button" className="btn btn-primary btn-sm"
                                  onClick={() => tog_xlarge(user)}
                                >
                                  {/* Modifica */}
                                  <i className="mdi mdi-lead-pencil"></i>
                                </button>
                              </td>
                              <td>
                                <button type="button" className="btn btn-danger btn-sm"
                                  onClick={() => rifiuta(user._id)}
                                >
                                  {/* Rimuovi */}
                                  <i className=" bx bx-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      ))}
                  </Table>

                  {user &&
                    <ModificaUser modal_modificaUser={modal_xlarge} setmodal_modificaUser={setmodal_xlarge} user={user} />
                  }

                </div>
              </CardBody>
            </div>
          </Card>

        }

        {users && users == 0 && <h5>Non sono presenti utenti da approvare</h5>}

        {/* 
        <Modal
          size="xl"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge()
          }}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0"
              id="myExtraLargeModalLabel">
              Modifica Utente
            </h5>
            <button
              onClick={() => {
                setmodal_xlarge(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>



          <div className="modal-body">
            <p>
              Info utente e multiselect per cambiare la sezione
            </p>

          </div>


          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_xlarge()
              }}
              className="btn btn-primary "
              data-dismiss="modal">
              Annulla
            </button>
            <button
              type="button"
              className="btn btn-danger"
            //onClick={() => modifica()}
            >
              Modifica
            </button>
          </div>
        </Modal> */}
      </Container>
    </div>
  )
}

export default UtentiInPending
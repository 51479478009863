import React, { useEffect } from "react"
import { Link } from "react-router-dom"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"
import comunicazioniImg from "../../assets/images/comunicazioni.jpg"
import datiSpoglioImg from "../../assets/images/statistiche.jpg"
import nuovaComunicazioneImg from "../../assets/images/nuova-comunicazione.jpg"
import segnalazioniImg from "../../assets/images/segnalazione.jpg"
import listaUtentImg from "../../assets/images/lista-utenti.jpg"
import nuovoUtente from "../../assets/images/nuovo-utente.jpg"
import insertdata from "../../assets/images/insertdata.jpg"
import img1 from "../../assets/images/small/img-1.jpg"
import axios from "axios"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

const Dashboard = () => {

  const userInfo = JSON.parse(localStorage.getItem("user"))
  const subscriptionStatus = JSON.parse(localStorage.getItem("subscription"))
  const token = JSON.parse(localStorage.getItem("token"))

  async function subscribe() {
    let sw = await navigator.serviceWorker.ready
    let push = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BOl-o0YwpCLKMVZ5bowSnIZwxAuuKCQjeBY_l2lDZCKVKnpP9IVf5CFGMDcihD2_WJOHaN_YF4KH9PM5_3Oe5zA",
    })

    const subscription = push

    axios
      .post("user/webpush/subscribe", {
        subscription,

      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(() => {
        localStorage.setItem("subscription", true)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  useEffect(() => {
    if (!subscriptionStatus) subscribe()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Home" />
          <h4 className="mb-4">Ciao {userInfo.firstName},  <br/>
          in base a ciò che devi fare puoi scegliere tra le opzioni in basso. </h4>

          <Row>
            <Col md={6} xl={4}>
              <Card>
                <img
                  className="card-img-top img-fluid"
                  style={{ height: '350px' }}
                  src={comunicazioniImg}
                  alt=""
                />
                <CardBody>
                  <h4 className="card-title">COMUNICAZIONI</h4>
                  <p className="card-text">Visualizza le ultime novità.</p>
                  <Link
                    to="/comunicazione"
                    className="btn btn-primary waves-effect waves-light">
                    Leggi
                  </Link>
                </CardBody>
              </Card>
            </Col>

            {(userInfo.role == 'Presidente Regionale' || userInfo.role == 'Coordinatore Provinciale') && (
            
            <Col md={6} xl={4}>
              <Card>
                <img
                  className="card-img-top img-fluid"
                  src={listaUtentImg}
                  alt=""
                  style={{ height: "350px" }}
                />
            <CardBody>
                  <h4 className="card-title">LISTA UTENTI DELLA TUA AREA</h4>
                  <p className="card-text">
                    Visualizza la lista utenti degli utenti nella tua area.
                  </p>
                  <Link
                    to="/utenti/rappresentanti"
                    className="btn btn-primary waves-effect waves-light">
                    Vai
                  </Link>
                </CardBody>
                </Card>
                </Col>
                )}
            {userInfo.role == "Partito Centrale" && (
              <Col md={6} xl={4}>
                  <Card>
                    <img
                      className="card-img-top img-fluid"
                      src={datiSpoglioImg}
                      alt=""
                      style={{ height: "350px" }}
                    />
                    <CardBody>
                      <h4 className="card-title">DATI SPOGLIO</h4>
                      <p className="card-text">
                        Visualizza i risultati delle ultime elezioni.
                      </p>
                      <Link
                        to="/data"
                        className="btn btn-primary waves-effect waves-light">
                        Leggi
                      </Link>
                    </CardBody>
                  </Card>
              </Col>
            )}
            <Col md={6} xl={4}>
              {(userInfo.role == "Presidente Regionale" ||
                userInfo.role == "Partito Centrale") && (
                  <Card>
                    <img
                      className="card-img-top img-fluid"
                      src={nuovoUtente}
                      alt=""
                      style={{ height: "350px" }}
                    />
                    <CardBody>
                      <h4 className="card-title">ISCRIVI UN NUOVO UTENTE</h4>
                      <p className="card-text">
                        Accedi alla pagina per iscrivere nuovi utenti alla
                        piattaforma.
                      </p>
                      <Link
                        to="/iscrivi-utenti"
                        className="btn btn-primary waves-effect waves-light">
                        Vai
                      </Link>
                    </CardBody>
                  </Card>
                )}


              {/* visibile solo ai rappresentanti - da cambiare? */}
              {userInfo.role == "Rappresentante di Lista" && (
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={insertdata}
                    alt=""
                    style={{ height: "350px" }}
                  />
                  <CardBody>
                    <h4 className="card-title">INSERIMENTO DATI SPOGLIO</h4>
                    <p className="card-text">Inserisci i dati dell'affluenza e dello spoglio dei voti.</p>
                    <Link
                      to="/inserimento-dati"
                      className="btn btn-primary waves-effect waves-light">
                      Vai
                    </Link>
                  </CardBody>
                </Card>
              )}
            </Col>

            
          </Row>
          <Row>
          {/* visibile solo per il partito */}
          {userInfo.role == "Partito Centrale" && (
            <>
              <Col md={6} xl={4}>
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={nuovaComunicazioneImg}
                    alt=""
                    style={{ height: "350px" }}
                  />
                  <CardBody>
                    <h4 className="card-title">PUBBLICA COMUNICAZIONE</h4>
                    <p className="card-text">
                      Pubblica una nuova comunicazione.
                    </p>
                    <Link
                      to="/nuova-comunicazione"
                      className="btn btn-primary waves-effect waves-light">
                      Vai
                    </Link>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} xl={4}>
                <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={listaUtentImg}
                    alt=""
                    style={{ height: "350px" }}
                  />
                  <CardBody>
                    <h4 className="card-title">LISTA UTENTI</h4>
                    <p className="card-text">
                      Visualizza la lista utenti e approva i nuovi iscritti.
                    </p>
                    <Link
                      to="/utenti"
                      className="btn btn-primary waves-effect waves-light">
                      Vai
                    </Link>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} xl={4}>
              <Card>
                  <img
                    className="card-img-top img-fluid"
                    src={comunicazioniImg}
                    alt=""
                    style={{ height: "350px" }}
                  />
              <CardBody>
                    <h4 className="card-title">LISTA RAPPRESENTANTI</h4>
                    <p className="card-text">
                      Visualizza l'elenco dei Rappresentanti di Lista.
                    </p>
                    <Link
                      to="/utenti/rappresentanti"
                      className="btn btn-primary waves-effect waves-light">
                      Vai
                    </Link>
                  </CardBody>
                  </Card>
                  </Col>
            </>
          )}
          
          </Row>


            
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard




export default async function jsonToCsvFormatter(data) {
    data = data.datiSpoglio
    const csv = []

    // Entro in camera o senato
    for (let [assemblea, value] of Object.entries(data)) {

        // Salvo il nome del collegio (uninominale, o plurinominale)
        for (let [collegio, collegioValue] of Object.entries(value.schedeValide)) {

            for (let [lista, listaValue] of Object.entries(collegioValue)) {

                switch (collegio) {
                    case "plurinominale":
                        csv.push({
                            ["ASSEMBLEA"]: assemblea.toUpperCase(),
                            ["COLLEGIO"]: collegio.toUpperCase(),
                            ["LISTA / PARTITO"]: lista,
                            ["VOTI"]: listaValue.voti
                        })
                        break

                    case "uninominale":

                        csv.push({
                            ["ASSEMBLEA"]: assemblea.toUpperCase(),
                            ["COLLEGIO"]: collegio.toUpperCase(),
                            ["VOTI"]: listaValue.voti,
                            ["CANDIDATO"]: lista,
                            ["LISTA / PARTITO"]: listaValue.coalizione.map((coalizione) => coalizione.lista).join(", ")
                        })
                        break

                    default:
                        break
                }
            }
        }
    }
    return csv
}

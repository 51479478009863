import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// Logo
import logo from '../../assets/images/fdi.png';

export default function RegistrationForm({ roleForm, handleOnSubmit }) {
  const [isChecked, setIsChecked] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().required(
        'Il campo è obbligatorio. Inserisci il tuo indirizzo email.'
      ),
      phone: Yup.string().required(
        'Il campo è obbligatorio. Inserisci il tuo numero di telefono'
      ),
      firstName: Yup.string().required(
        'Il campo è obbligatorio. Inserisci il tuo nome.'
      ),
      lastName: Yup.string().required(
        'Il campo è obbligatorio. Inserisci il tuo cognome.'
      ),
      password: Yup.string()
        .required('Il campo è obbligatorio. Inserisci una password.')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'La password deve contenere 8 caratteri di cui almeno un carattere maiuscolo, un numero e un carattere speciale'
        ),
    }),
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  return (
    <>
      <div className='auth-page'>
        <Container fluid className='p-0'>
          <Row className='g-0 justify-content-center'>
            <Col xl={4} md={5} className='col-xxl-3'>
              <div className='auth-full-page-content d-flex p-sm-5 p-4'>
                <div className='w-100'>
                  <div className='d-flex flex-column h-100'>
                    <div className='mb-4 mb-md-5 text-center'>
                      <Link to='/dashboard' className='d-block auth-logo'>
                        <img src={logo} alt='' height='70' />
                      </Link>
                    </div>
                    <div className='auth-content my-auto'>
                      <div className='text-center'>
                        <h5 className='mb-0'>Benvenuto!</h5>
                        <h6 className='mb-0'>Compila il form e registrati</h6>
                      </div>

                      <Form
                        className='needs-validation custom-form mt-4 pt-2'
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className='mb-3'>
                          <Label className='form-label'>Email</Label>
                          <Input
                            id='email'
                            name='email'
                            type='email'
                            className='form-control'
                            placeholder='Inserisci la tua email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>
                            Numero di telefono
                          </Label>
                          <Input
                            id='phone'
                            name='phone'
                            type='text'
                            className='form-control'
                            placeholder='Inserisci il tuo numero di telefono'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ''}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>Nome</Label>
                          <Input
                            id='firstName'
                            name='firstName'
                            type='text'
                            className='form-control'
                            placeholder='Inserisci il tuo nome'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ''}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>Cognome</Label>
                          <Input
                            id='lastName'
                            name='lastName'
                            type='text'
                            className='form-control'
                            placeholder='Inserisci il tuo cognome'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ''}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label className='form-label'>Password</Label>
                          <Input
                            id='password'
                            name='password'
                            type='password'
                            className='form-control'
                            placeholder='Scegli la tua password'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        {roleForm}

                        <div className='mb-3'>
                          <button
                            className='btn btn-primary w-100 waves-effect waves-light'
                            type='submit'
                            disabled={isChecked ? false : true}
                          >
                            Registrati
                          </button>
                        </div>
                        <Label check>
                          <Input
                            type='checkbox'
                            checked={isChecked}
                            onChange={() => setIsChecked(() => !isChecked)}
                          />{' '}
                          Autorizzo espressamente Fratelli d'Italia al
                          trattamento dei miei dati per finalità informative
                          sulle iniziative associative e politiche del partito.
                          <br></br> Puoi consultare la nostra informativa del
                          trattamento dati cliccando{' '}
                          <a href='https://www.fratelli-italia.it/privacy/'>
                            qui
                          </a>
                        </Label>
                      </Form>
                      <div className='mt-5 text-center'>
                        <p className='text-muted mb-0'>
                          Hai già un account?
                          <Link
                            to='/login'
                            className='text-primary fw-semibold'
                            style={{ paddingLeft: '5px' }}
                          >
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

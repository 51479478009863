import React, { useState } from "react";
import { Container, Button, Toast, ToastBody, ToastHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { newCommunication } from "../../helpers/fakebackend_helper";

const NuovaComunicazione = () => {
  //meta title
  document.title = "Nuova Comunicazione | Gestione Rappresentanti di Lista";

  const [toast, setToast] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subtitle, setSubtitle] = useState("");

  let history = useHistory();

  const data = {
    title: title,
    description: description,
    subtitle: subtitle,
  };

  function posta() {
    newCommunication(data)
      .then(
        setToast(true),
       setTimeout(()=> {
          history.push("/comunicazione")
        }, 3000)
      )
      .catch((err) => console.log(err));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Comunicazioni"
            breadcrumbItem="Nuova Comunicazione"
          />
          {/* QUI IL PARTITO CARICA LE NEWS - ACCESSO: PARTITO */}

          {/* Toast */}
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: 1005 }}
          >
            <Toast isOpen={toast}>
              <ToastHeader>
              <i className="bx bx-check-circle me-2 text-success"></i>

                Operazione eseguita</ToastHeader>
              <ToastBody>Comunicazione caricata con successo!</ToastBody>
            </Toast>


          </div>
          <form action="javascript:void(0);">
            <div className="mb-3">
              <label className="form-label" htmlFor="formrow-title-input">
                Titolo
              </label>
              <input
                name="title"
                type="text"
                className="form-control"
                id="formrow-title-input"
                placeholder="Titolo"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label className="form-label" htmlFor="formrow-subtitle-input">
                Sottotitolo
              </label>
              <input
                name="subtitle"
                type="text"
                className="form-control"
                id="formrow-subtitle-input"
                placeholder="Sottotitolo"
                onChange={(e) => setSubtitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="formrow-description-input">
                Testo
              </label>
              <input
                name="description"
                type="textarea"
                className="form-control"
                id="formrow-description-input"
                placeholder="Testo"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <Button
              className="btn bg-primary"
              onClick={() => posta()}
              disabled={!title || !subtitle || !description}
            >
              Pubblica
            </Button>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NuovaComunicazione;

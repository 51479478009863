import "../../assets/scss/theme.scss"
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/fdi.png";



import { postSignup } from "../../helpers/fakebackend_helper";

const verifyRegister = (props) => {

 
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0 justify-content-center">
            <Col lg={4} md={5} className="col-xxl-8">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="80" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h1 className="rdl_colored">Registrazione avvenuta con successo!</h1>
                        <hr className="rdl_colored"/>
                        <h2 className="rdl_color">Per favore, controlla la tua posta elettronica per attivare il tuo account</h2>
                      </div>

                      

                      
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* <CarouselPage /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default verifyRegister;

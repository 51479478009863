import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { postNewPassword } from "../../helpers/fakebackend_helper"

// import images
import logo from "../../assets/images/fdi.png"

const ChangePassword = () => {
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")

  let history = useHistory()
  const token = history.location.search.split("=")[2]
  console.log(token)

  function onSubmit() {
    if (!password || !repeatPassword)
      return toast.error("Inserisci una password")
    if (password !== repeatPassword)
      return toast.error("Le password non coincidono")
    postNewPassword(
      { password: password.trim() },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        toast.success(res.message)
        setTimeout(() => history.push("/login"), 2000)
      })
      .catch((err) => toast.error(err.response.data.message))
  }

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0 justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="70" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Reimposta password</h5>
                        <p className="text-muted mt-2">
                          {" "}
                          Inserisci una nuova password.
                        </p>
                      </div>
                      <div
                        className="alert alert-success text-center mb-4 mt-4 pt-2"
                        role="alert">
                        La password deve contenere 8 caratteri di cui almeno un
                        carattere maiuscolo, un numero e un carattere speciale
                      </div>
                      <form className="custom-form mt-4">
                        <div className="mb-3">
                          <label className="form-label">
                            Inserisci password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="email"
                            placeholder="Inserisci nuova password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Ripeti password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="repeatPassword"
                            placeholder="Ripeti nuova password"
                            name="repeatPassword"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 mt-4">
                          <button
                            disabled={password !== repeatPassword}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="button"
                            onClick={onSubmit}>
                            Invia
                          </button>
                        </div>
                      </form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Torna alla pagina di{" "}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold">
                            Login{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword

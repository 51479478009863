import React, { useState } from "react";
import { CardBody, NavItem, TabContent, TabPane, NavLink, Form, Input, FormFeedback } from "reactstrap";

import Select from "react-select";

import classnames from "classnames";
import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { Formik, useFormik } from "formik";


const BasicPills = () => {
  const [activeTab, setactiveTab] = useState(1);

  //select
  const [selectedGroup, setselectedGroup] = useState(null);
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ];


//tab wizard
  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
      }
    }
  }


      // Form validation - controllo campi e inserimento msg errore 
      const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          username: '',
          firstname: '',
          lastname: '',
          telephone: '',
          email: '',
          alphanumeric: '',
        },
        validationSchema: Yup.object().shape({
          username: Yup.string().required(
            "Il campo è obbligatorio"
          ),
          firstname: Yup.string().required(
            "Il campo è obbligatorio"
          ),
          lastname: Yup.string().required(
            "Il campo è obbligatorio"
          ),
          telephone: Yup.string().required(
            "Il campo è obbligatorio"
          ),
          email: Yup.string()
            .email("Inserire un indirizzo email valido")
            .max(255)
            .required("Il campo è obbligatorio"),
        }),

        onSubmit: (values) => {
          console.log("values", values);
        }
      });

  return (
    <React.Fragment>
      <CardBody>
        <div id="basic-pills-wizard" className="twitter-bs-wizard">
          <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === 1 })}
                onClick={() => {
                  setactiveTab(1);
                }}
              >
                <div
                  className="step-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Informazioni personali"
                >
                  <i className="bx bx-list-ul"></i>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === 2 })}
                onClick={() => {
                  setactiveTab(2);
                }}
              >
                <div
                  className="step-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Sezione di appartenenza"
                >
                  <i className="bx bxs-bank"></i>
                </div>
              </NavLink>
            </NavItem>
          </ul>

          <TabContent
            className="twitter-bs-wizard-tab-content"
            activeTab={activeTab}
          >
            <TabPane tabId={1}>
              <div className="text-center mb-4">
                <h5>Informazioni personali</h5>
                <p className="card-title-desc">
                  Compila i campi sottostanti per candidarti
                </p>
              </div>
              <Form >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Nome
                      </label>
                      <Input
                       name="firstname"
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.firstname || ""}
                        invalid={
                          validationType.touched.firstname &&
                          validationType.errors.firstname
                            ? true
                            : false
                        }
                      />
                       {validationType.touched.firstname &&
                      validationType.errors.firstname ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.firstname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-lastname-input"
                        className="form-label"
                      >
                        Cognome
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-lastname-input"
                        name="lastname"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.lastname || ""}
                        invalid={
                          validationType.touched.lastname &&
                          validationType.errors.lastname
                            ? true
                            : false
                        }
                      />
                      {validationType.touched.lastname &&
                      validationType.errors.lastname ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.lastname}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-phoneno-input"
                        className="form-label"
                      >
                        Telefono
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-phoneno-input"
                        name="telephone"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.telephone || ""}
                        invalid={
                          validationType.touched.telephone &&
                          validationType.errors.telephone
                            ? true
                            : false
                        }
                      />
                       {validationType.touched.telephone &&
                      validationType.errors.telephone ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.telephone}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-email-input"
                        className="form-label"
                      >
                        Email
                      </label>
                      <Input
                      name="email"
                        type="email"
                        className="form-control"
                        id="basicpill-email-input"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.email || ""}
                        invalid={
                          validationType.touched.email &&
                          validationType.errors.email
                            ? true
                            : false
                        }
                      />
                        {validationType.touched.email &&
                      validationType.errors.email ? (
                        <FormFeedback type="invalid">
                          {validationType.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Form>
            </TabPane>
            <TabPane tabId={2}>
              <div>
                <div className="text-center mb-4">
                  <h5>Scegli sezione di appartenenza</h5>
                  <p className="card-title-desc">
                    Aggiungere select dinamici per regione, prov, città e
                    sezione
                  </p>
                </div>
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Regione
                        </label>

                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Provincia
                        </label>
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Città
                        </label>
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-default"
                          className="form-label font-size-13 text-muted"
                        >
                          Sezione
                        </label>
                        <Select
                          value={selectedGroup}
                          onChange={() => {
                            handleSelectGroup();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </TabPane>
          </TabContent>
          <ul className="pager wizard twitter-bs-wizard-pager-link">
            <li className={activeTab === 1 ? "previous disabled" : "previous"}>
              <Link
                to="#"
                className={
                  activeTab === 1
                    ? "btn btn-primary disabled"
                    : "btn btn-primary"
                }
                onClick={() => {
                  toggleTab(activeTab - 1);
                }}
              >
                <i className="bx bx-chevron-left me-1"></i> Indietro
              </Link>
            </li>

            <li className={activeTab === 2 ? "next disabled" : "next"}>
              {activeTab === 1 ? (
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={() => {
                    toggleTab(activeTab + 1);
                  }}
                >
                  Avanti <i className="bx bx-chevron-right ms-1"></i>
                </Link>
              ) : (
                <button type="submit" className="btn btn-primary w-md"
                //  disabled={!Formik.isValid}
                 >
                  Invia
                </button>
              )}
            </li>
          </ul>
        </div>
      </CardBody>
    </React.Fragment>
  );
};

export default BasicPills;

import React, { useState, useEffect } from "react"
import RegistrationForm from "../../components/Common/RegistrationForm"
import {
  getRegioni,
  getProvince,
  getComuni,
  getSezioni,
  postSignup,
} from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify"

export default function RegisterRappresentante() {
  const [regioni, setRegioni] = useState(null)
  const [province, setProvince] = useState(null)
  const [comuni, setComuni] = useState(null)
  const [sezioni, setSezioni] = useState(null)

  const [regione, setRegione] = useState("")
  const [provincia, setProvincia] = useState("")
  const [comune, setComune] = useState("")
  const [sezione, setSezione] = useState("")

  const role = "Rappresentante di Lista"

  const handleOnSubmit = (inputsData) => {
    if (regione && provincia && comune && sezione) {
      postSignup({
        ...inputsData,
        role,
        regione,
        provincia,
        comune,
        sezioni: [sezione],
      })
        .then(() => window.location.replace("/page-register-verification"))
        .catch((err) => toast.error(err.response.data.message))
    }
  }

  const handleFetchRegioni = () => {
    getRegioni({ params: { role } })
      .then((res) => {
        setRegioni(res)
      })
      .catch((err) => toast.error(err.message))
  }
  const handleFetchProvince = (regione) => {
    getProvince({ params: { regione, role } })
      .then((res) => {
        setProvince(res)
      })
      .catch((err) => toast.error(err.message))
  }

  const handleFetchComuni = (provincia) => {
    getComuni({ params: { provincia, role } })
      .then((res) => {
        setComuni(res)
      })
      .catch((err) => toast.error(err.message))
  }

  const handleFetchSezioni = (comune) => {
    getSezioni({ params: { comune, role } })
      .then((res) => {
        setSezioni(res)
      })
      .catch((err) => toast.error(err.message))
  }

  useEffect(() => handleFetchRegioni(), [])
  useEffect(() => {
    if (regione) handleFetchProvince(regione)
  }, [regione])
  useEffect(() => {
    if (regione && provincia) handleFetchComuni(provincia)
  }, [provincia])
  useEffect(() => {
    if (regione && provincia && comune) handleFetchSezioni(comune)
  }, [comune])

  return (
    <>
      <RegistrationForm
        handleOnSubmit={handleOnSubmit}
        roleForm={
          <>
            <div className="mb-3" style={{ marginTop: "40px" }}>
              {/* <h6>Seleziona la tua area di appartenenza</h6> */}
              <select
                key="reg"
                id="regione"
                className="form-select mb-3"
                name="regione"
                defaultValue={"default"}
                onChange={(e) => setRegione(e.target.value)}>
                <option
                  value="default"
                  label="Seleziona una regione"
                  key="regionedef"
                  hidden></option>
                {regioni &&
                  regioni.map((regione, i) => (
                    <option
                      key={i}
                      name={regione}
                      label={regione.toUpperCase()}
                      value={regione}>
                      {regione}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <select
                key="prov"
                id="provincia"
                className="form-select mb-3"
                name="provincia"
                defaultValue={"default"}
                onChange={(e) => setProvincia(e.target.value)}
                disabled={!regione}>
                <option
                  value="default"
                  label="Seleziona una provincia"
                  key="provinciadef"
                  hidden></option>
                {province &&
                  province.map((provincia, i) => (
                    <option
                      key={i}
                      name={provincia}
                      label={provincia.toUpperCase()}
                      value={provincia}>
                      {provincia}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <select
                key="com"
                id="comune"
                className="form-select mb-3"
                name="comune"
                defaultValue={"default"}
                disabled={!provincia}
                onChange={(e) => setComune(e.target.value)}>
                <option
                  value="default"
                  label="Seleziona un comune"
                  key="comunedef"
                  hidden></option>
                {comuni &&
                  comuni.map((comune, i) => (
                    <option
                      key={i}
                      name={comune}
                      label={comune.toUpperCase()}
                      value={comune}>
                      {comune}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-3">
              <select
                key="sez"
                id="sezione"
                className="form-select mb-3"
                name="sezione"
                defaultValue={"default"}
                disabled={!comune}
                onChange={(e) => setSezione(e.target.value)}>
                <option
                  value="default"
                  label="Seleziona una sezione"
                  key="sezionedef"
                  hidden
                  onChange={(e) => setSezione(e.target.value)}></option>
                {sezioni &&
                  sezioni.map((sezione, i) => (
                    <option
                      key={i}
                      name={sezione}
                      label={sezione}
                      value={sezione}>
                      {sezione}
                    </option>
                  ))}
              </select>
            </div>
          </>
        }
      />
    </>
  )
}

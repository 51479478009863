import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { useHistory } from "react-router-dom";
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {

  postJwtRegister, postSignup
} from "../../../helpers/fakebackend_helper"




// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()
toast.configure()

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {


  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      )
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postSignup, user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

    }




  } catch (error) {


    toast.configure()
    


    
    toast.error(error.response.data.message)



    setTimeout((e) => {
      
      window.location.replace("/login");
    }, 4000);
    




  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga

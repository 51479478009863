import "../../assets/scss/theme.scss"
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Alert,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
} from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/fdi.png";

import axios, { Axios } from 'axios';

import { postSignup } from "../../helpers/fakebackend_helper";

const ActivateAccount = (props) => {
    const [isLoading, setIsLoading] = useState(true)

    const [success, setSuccess] = useState(undefined)
    useEffect(() => {
       
        const queryParams = new URLSearchParams(window.location.search)
        const userId = queryParams.get("userId")
        console.log(userId)
        
        axios.get( `/user/confirmation/${userId}`).then((res) => {
            setIsLoading(false)
            setSuccess(res.data.success)
            setTimeout(() => {
                window.location.replace('/login')
            }, 3000);
        }).catch((e) => {
            console.error(e)
            setIsLoading(false)
            setSuccess(false)
        })
    })

    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 justify-content-center">
                        <Col lg={4} md={5} className="col-xxl-8">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="80" />
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="text-center">
                                                {isLoading && <h1 className="rdl_colored">Attivazione account...</h1>}
                                                {success === false && <h1 className="rdl_colored">Attivazione non riuscita.</h1>}
                                                {success === true && <h1 className="rdl_colored">Account Attivato.</h1>}

                                            </div>




                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <CarouselPage /> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ActivateAccount;

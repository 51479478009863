import React, { useState, useEffect } from "react";
import { Table, Card, Col, Row } from "reactstrap";
import style from './style.module.scss'
import { postRisultati } from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify";

const DatiVoti = ({ collUni, collegio_plurinominale, camera, comune, sezione, datiSezione, updateWarningMessage }) => {
  const [votazioni, setVotazioni] = useState({})

  useEffect(() => {
    const obj = { [camera]: {} }

    if (datiSezione?.datiSpoglio?.[camera]?.schedeValide?.uninominale) {
      obj[camera].uninominale = datiSezione.datiSpoglio[camera].schedeValide.uninominale
    }

    if (datiSezione?.datiSpoglio?.[camera]?.schedeValide?.plurinominale) {
      obj[camera].plurinominale = datiSezione.datiSpoglio[camera].schedeValide.plurinominale
    }

    setVotazioni(obj)
  }, [datiSezione])

  console.log(datiSezione)


  function handleChangeVotazioni(e, assemblea, coalizione) {
    const { name, value } = e.target;
    const obj = { ...votazioni }

    if (!obj[camera]) obj[camera] = {}
    if (!obj[camera][assemblea]) obj[camera][assemblea] = {}
    if (assemblea === "uninominale") {
      var coalizioniConverted = coalizione.map(function (item) {
        return item['lista'];
      });
      obj[camera][assemblea][name] = {}
      obj[camera][assemblea][name].coalizione = coalizioniConverted
      obj[camera][assemblea][name].voti = parseInt(value)
      return
    }
    obj[camera][assemblea][name] = parseInt(value)
    setVotazioni(obj)
  }

  function handlePostDati() {
    const params = { camera, comune, sezione: sezione[0] }
    const data = { ...votazioni[camera] }
    postRisultati(data, { params })
      .then(_ => {
        toast.success("Dati inviati con successo")
        updateWarningMessage()
      })
      .catch(_ => toast.error("Errore nell'invio dei dati"))
  }

  return (
    <div>
      <h3>Inserimento dati Votazioni</h3>
      <p>I dati fanno riferimento alle sezioni di appartenenza</p>

      {/* CAMERA PLURINOMINALE */}
      <Row className="mt-5">
        {collegio_plurinominale && sezione && (
          <Col md={12} xl={6}>
            <h4>Collegio Plurinominali</h4>
            <Card style={{ border: "None" }}>
              <Table className="table mb-0">
                <thead style={{ border: "0.5px #d2dae2 solid " }}>
                  <tr>
                    <th>Coalizione</th>
                    <th>Voti</th>
                  </tr>
                </thead>

                {/* body */}
                <tbody className="customTable" style={{ border: "0.5px #d2dae2 solid " }}>
                  {collegio_plurinominale.map((item, index) => (
                    <tr key={index} style={{ border: "0.5px #d2dae2 solid " }}>
                      <td className={style.namesContainer}
                        style={{
                          display: "flex", alignItems: 'center',
                          border: "0px #d2dae2 solid",
                        }}>
                        <img alt="partito_image" src={item.logo} className={style.logo}
                          style={{ marginBottom: "10px" }}
                        />
                        <span className={style.names}>{item.nome}</span>
                      </td>

                      <td style={{ border: "0px #d2dae2 solid", }}>
                        <input
                          type="number"
                          className="form-control "
                          name={item.nome}
                          onChange={(e) => handleChangeVotazioni(e, "plurinominale")}
                          defaultValue={datiSezione && datiSezione?.datiSpoglio?.[camera]?.schedeValide?.plurinominale?.[item.nome]}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <button
                className="btn btn-primary mt-3 ms-2"
                style={{ width: "fit-content" }}
                onClick={handlePostDati}
              >
                Invia dati
              </button>
            </Card>
          </Col>
        )}

        {/* CAMERA UNINOMINALE */}
        {(collUni && sezione) && (
          <Col md={12} xl={6}>
            <h4>Collegio Uninominali</h4>
            <Card style={{ border: "None" }}>
              <Table className="table mb-0" >
                <thead style={{ border: "0.5px #d2dae2 solid " }}>
                  <tr>
                    <th>Coalizione</th>
                    <th>Voti</th>
                  </tr>
                </thead>

                {/* body */}
                <tbody style={{ border: "0.5px #d2dae2 solid " }}>
                  {collUni.map((item, index) => (
                    <tr key={index} style={{ border: "0.5px #d2dae2 solid " }}>
                      <h5> {item.nameCandidato}</h5>
                      <td className={style.namesContainer}
                        style={{
                          display: "flex", alignItems: 'center',
                          border: "0px #d2dae2 solid", marginRight: "50px"
                        }}>
                        {
                          item.coalizione && item.coalizione.map((item, index) => (
                            <img key={index} alt="partito_image" src={item.logo} className={style.logo} style={{ marginRight: "-40px", marginBottom: "10px", maxHeight: "80px" }} />
                          ))
                        }
                      </td>

                      <td>
                        {item.coalizione && item.coalizione.map((item, index) => (
                          <p style={{ borderBottom: "#e0e0e0 solid 1px", marginBottom: "0px" }} key={index}>{item.lista}</p>
                        ))
                        }
                      </td>

                      <td style={{ border: "0px #d2dae2 solid", }}>
                        <input
                          type="number"
                          className="form-control "
                          name={item.nameCandidato}
                          onChange={(e) => handleChangeVotazioni(e, "uninominale", item.coalizione)}
                          defaultValue={datiSezione && datiSezione.datiSpoglio?.[camera]?.schedeValide?.uninominale?.[item.nameCandidato]?.voti}
                        ></input>
                      </td>
                    </tr>
                  ))
                  }

                </tbody>
              </Table>
              <button
                className="btn btn-primary mt-3 ms-2 "
                style={{ width: "fit-content" }}
                onClick={handlePostDati}
              >
                Invia dati
              </button>
            </Card>
          </Col>

        )}
      </Row>
    </div>
  );
};
<style>


</style>

export default DatiVoti;

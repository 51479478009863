import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { toast } from "react-toastify"
import { getResetPassword } from "../../helpers/fakebackend_helper"

// import images
import logo from "../../assets/images/fdi.png"

const RecoverPassword = () => {
  const [email, setEmail] = useState("")

  function onSubmit() {
    if (!email) return toast.error("Inserisci una email")
    getResetPassword({ params: { email: email.trim() } })
      .then((res) => toast.success(res.message))
      .catch((err) => toast.error(err.response.data.message))
  }

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0 justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="70" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Recupera password</h5>
                        <p className="text-muted mt-2">
                          {" "}
                          Inserisci l'indirizzo email con cui ti sei registrato.
                        </p>
                      </div>
                      <div
                        className="alert alert-success text-center mb-4 mt-4 pt-2"
                        role="alert">
                        Ti invieremo un'email per impostare una nuova password.
                      </div>
                      <form className="custom-form mt-4">
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Inserisci email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 mt-4">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="button"
                            onClick={onSubmit}>
                            Invia
                          </button>
                        </div>
                      </form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Torna alla pagina di{" "}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecoverPassword

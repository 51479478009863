import React, { useState, useEffect } from "react"
import { Card, Container, Row, Col, Input, Button, Table } from "reactstrap"
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Doughnut from "../AllCharts/echart/doughnutchart"
import { getDati, getSezione, getRisultati, getRegioniSpoglio, getComuniSpoglio, getSezioniSpoglio, getCollegiSpoglio } from "../../helpers/fakebackend_helper"
import Risultati from "./Risultati"
import { CSVLink } from "react-csv"
import jsonToCsvFormatter from "../../assets/jsonToCsvFormatter"
import {  Spinner } from "reactstrap"
import XLSX from "xlsx";
import { toast } from "react-toastify"


const Dati = () => {
  //meta title
  document.title = "Risultati Elezioni 2022 | Gestione Rappresentanti di Lista"

  const [elezione, setElezione] = useState("")
  const [area, setArea] = useState("")
  const [regione, setRegione] = useState("")
  //const [collegio, setCollegio] = useState("")
  const [tipologiaCollegio, setTipologiaCollegio] = useState("") // Uninominale o Plurinominale
  const [collegio, setCollegio] = useState("")
  const [comune, setComune] = useState("")

  const [regioniNomi, setRegioniNomi] = useState([])
  const [collegiNomi, setCollegiNomi] = useState([])
  const [comuniNomi, setComuniNomi] = useState([])
  const [sezioniNomi, setSezioniNomi] = useState([])
  const [risultatiElettorali, setRisultatiElettorali] = useState(null)

  const [risultati, setRisultati] = useState(null)
  const [sezione, setSezione] = useState([])
  const [loading, setLoading] = useState(false)

  const [statoDiProva, setStatoDiProva] = useState(null)
  const [abilitaPulsanteRisultati, setAbilitaPulsanteRisultati] = useState(null)
  const [nomeExcel, setNomeExcel] = useState(null)

  // Handler
  function handleGetDatiNazionali() {
    // setRegioniNomi([])
    // setRegione("")
    // setTipologia("")
    // setCollegiNomi([])
    // setCollegio('')
    // setComuniNomi([])
    // setComune("")
    // const params = { elezione, area }
    // getDati({ params })
    //   .then((res) => {
    //     setCircoscrizioninomi(res.circoscrizioniNomi)
    //     setRisultatiElettorali(res.affluenza.risultati[0]["2018"].affluenza)
    //   })
    //   .catch((err) => console.log(err))
  }

  // function handleGetDatiCircoscrizione() {
  //   setCollPluriNomi([])
  //   setCollPluri("")
  //   setCollUniNomi([])
  //   setCollUni("")
  //   const params = { elezione, area, circoscrizione }
  //   getDati({ params })
  //     .then((res) => {
  //       setCollPluriNomi(res.collegiPlurNomi)
  //       setRisultatiElettorali(res.affluenza.risultati[0]["2018"].affluenza)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // function handleGetDatiCollPlur() {
  //   setCollUniNomi([])
  //   setCollUni("")
  //   const params = {
  //     elezione,
  //     area,
  //     circoscrizione,
  //     collegioPlurinominale: collPluri,
  //   }
  //   getDati({ params })
  //     .then((res) => {
  //       setCollUniNomi(res.collegiUniNomi)
  //       setRisultatiElettorali(res.affluenza.risultati[0]["2018"].affluenza)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // function handleGetDatiCollUni() {
  //   const params = {
  //     // elezione,
  //     // area,
  //     // circoscrizione,
  //     // collegioPlurinominale: collPluri,
  //     // collegioUninominale: collUni,
  //   }
  //   getDati({ params })
  //     .then((res) => {
  //       setRisultatiElettorali(res.affluenza.risultati[0]["2018"].affluenza)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // useEffect(() => {
  //   if (area) handleGetDatiNazionali()
  // }, [area])
  // useEffect(() => {
  //   getRegioniSpoglio()
  //   if (circoscrizione) handleGetDatiCircoscrizione()
  // }, [circoscrizione])
  // useEffect(() => {
  //   if (collPluri) handleGetDatiCollPlur()
  // }, [collPluri])
  // useEffect(() => {
  //   if (collUni) handleGetDatiCollUni()
  // }, [collUni])

  //const ComuneUtente = JSON.parse(localStorage.getItem("user"))

  // useEffect(() => {
  //   getSezione()
  //   .then(response => setSezione(response.sezioni) )
  //   .catch((err) => console.log(err))
  // }, [])

  //NUOVA CHIAMATA PER PRENDERE I RISULTATI SPOGLIO
  // useEffect(() => {
  //   const params = {
  //     camera: elezione,
  //     regione: regione,
  //     collegio: collegio,
  //     comune: comune,
  //   }

  //   //sezione: sezione,
  //   getRisultati(params)
  //   .then(response =>{
  //     //console.log(response)
  //     setRisultati(response)
  //   } )
  //   .catch((err) => console.log(err))
  // }, [sezione])

  const mostraRisultati = () => {
    setLoading(true)
    const params = {}
    params.camera = elezione

    const arrayData = [
      { name: 'regione', value: regione },
      { name: 'collegio', value: collegio },
      { name: 'comune', value: comune },
      { name: 'sezione', value: sezione }
    ]

    setNomeExcel(arrayData)

    const newData = arrayData.filter(el => el.value != "")
    const lastElement = newData[newData.length - 1]
    params[lastElement.name] = lastElement.value


    if (lastElement.name === "sezione") {
      params.comune = comune
    }

    if (lastElement.name === "collegio") {
      if (tipologiaCollegio === 'uninominale') {
        params.collegioUninominale = collegio
      }
      if (tipologiaCollegio === 'plurinominale'){
        params.collegioPlurinominale = collegio
      }
    }
    console.log(params)

    getRisultati(params)
     .then(response =>{
      console.log(response)
      setRisultati(response)
      setLoading(false)

      jsonToCsvFormatter(response)
        .then(res => setAbilitaPulsanteRisultati(res))
    } )
    .catch((err) =>{
      console.log(err)
      setLoading(false)
    })
  }
  console.log(risultati)

  ////////////////////////////////////////////////////////

  // useEffect reset risultati
  useEffect(()=>{
    setRisultati(null)
    setAbilitaPulsanteRisultati(null)
  }, [elezione, area, regione, collegio, comune, sezione])



  // useEffect camera 
  useEffect(() => {
    if (area === "VALLE D'AOSTA") {
      const paramsCollegi = {
        camera: elezione,
        tipo: 'uninominale',
        regione: "VALLE D'AOSTA",
      }
      getCollegiSpoglio(paramsCollegi)
      .then(res => {
        setCollegiNomi(() => res)
      })
      .catch((err) => console.log(err))
    }else{
      //Reset Campi
      setCollegio("")
      setCollegiNomi([])
      setComune("")
      setComuniNomi([])
      setSezione("")
      setSezioniNomi([])
    }
    setCollegio("")
    setCollegiNomi([])
    setComune("")
    setComuniNomi([])
    setSezione("")
    setSezioniNomi([])
  },[elezione])

  // useEffect Area
  useEffect(() => {
    if (area === "VALLE D'AOSTA") {
      const paramsCollegi = {
        camera: elezione,
        tipo: 'uninominale',
        regione: "VALLE D'AOSTA",
      }
      getCollegiSpoglio(paramsCollegi)
      .then(res => {
        setCollegiNomi(() => res)
      })
      .catch((err) => console.log(err))
      setComune("")
      setComuniNomi([])
      setSezione("")
      setSezioniNomi([])
    }else{
      //Reset Campi
      setRegione("")
      setRegioniNomi([])
      setCollegio("")
      setCollegiNomi([])
      setComune("")
      setComuniNomi([])
      setSezione("")
      setSezioniNomi([])
    }
    
  }, [area])

  //useEffect Tipologia Collegi
  // useEffect(() => {
  //   if (area === "VALLE D'AOSTA") {
      // const paramsCollegi = {
      //   camera: elezione,
      //   tipo: 'uninominale',
      //   regione: "VALLE D'AOSTA",
      // }
      // getCollegiSpoglio(paramsCollegi)
      // .then(res => {
      //   setCollegiNomi(res)
      // })
      // .catch((err) => console.log(err))
      
      //Reset Campi
    //   setComune("")
    //   setComuniNomi([])
    //   setSezione("")
    //   setSezioniNomi([])
    // }else{
    //   setRegione("")
    //   setRegioniNomi([])

    //   getRegioniSpoglio()
    //   .then(res => {
    //     const newRegioni = res.filter(el => el != 'VALLE D\'AOSTA')
    //     setRegioniNomi(newRegioni)
    //   })
    //   .catch((err) => console.log(err))

    //   setCollegio("")
    //   setCollegiNomi([])
    //   setComune("")
    //   setComuniNomi([])
    //   setSezione("")
    //   setSezioniNomi([])
  //   }
  // }, [tipologiaCollegio])

  // useEffect Regioni
  useEffect(() => {

    if (area ==="VALLE D'AOSTA"){
      setRegione("VALLE D'AOSTA")
      setRegioniNomi(["VALLE D'AOSTA"])

      const paramsCollegi = {
        camera: elezione,
        tipo: 'uninominale',
        regione: "VALLE D'AOSTA",
      }

      getCollegiSpoglio(paramsCollegi)
      .then(res => {
        setCollegiNomi(res)
      })
      .catch((err) => console.log(err))
      
      //Reset Campi
      setComune("")
      setComuniNomi([])
      setSezione("")
      setSezioniNomi([])

    }else{
      getRegioniSpoglio()
        .then(res => {
          const newRegioni = res.filter(el => el != "VALLE D'AOSTA")
          // Ordinamento alfabetico
          newRegioni?.sort((a, b) => {
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
          })
          setRegioniNomi(newRegioni)
        })
      .catch((err) => console.log(err))

      setRegione("")
      setRegioniNomi([])
      // setCollegio("")
      // setCollegiNomi([])
      setComune("")
      setComuniNomi([])
      setSezione("")
      setSezioniNomi([])
    }
      
  }, [tipologiaCollegio])

  // useEffect Collegi
  useEffect(()=>{
    if (area !== "VALLE D'AOSTA") {
      setCollegio("")
      setCollegiNomi([])
    }
  
    const paramsCollegi = {
      camera: elezione,
      tipo: tipologiaCollegio,
      regione: regione,
    }
    if (regione) {
      getCollegiSpoglio(paramsCollegi)
      .then(res => {
        setCollegiNomi(res)
      })
      .catch((err) => console.log(err))
    }
    
    //Reset Campi
    setComune("")
    setComuniNomi([])
    setSezione("")
    setSezioniNomi([])
      
  },[regione])

  // useEffect Comuni
  useEffect(() => {
    setComune("")
    setComuniNomi([])
    const params = {
      camera: elezione,
      tipo: tipologiaCollegio,
      collegio: collegio
    }

    getComuniSpoglio(params)
      .then( res =>{
        if (res.length === 0) { 
          setComuniNomi(null)
        }else{
          res.sort((a, b) => {
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
          })
          setComuniNomi(res)
        }
        
      })
      .catch((err) => console.log(err))
    
    setSezione("")
    setSezioniNomi([])

  }, [collegio])

  // useEffect Sezione
  useEffect(() => {
    getSezioniSpoglio({ comune: comune })
      .then(res => {
        setSezioniNomi(res)
      })
      .catch((err) => console.log(err))
  }, [comune])

  useEffect(()=>{
    setRegione("")
    setRegioniNomi([])
    setTipologiaCollegio("")
    setCollegio("")
    setCollegiNomi([])
    setComune("")
    setComuniNomi([])
    setSezione("")
    setSezioniNomi([])

  }, [elezione])
  
  const downloadExcel = (data) => {
    const obj = nomeExcel
    delete obj.camera

    let string = "dati"

    for (const o of obj) {
      if (o.value) string += "_" + o.value
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${string}.xlsx`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Spoglio"
            breadcrumbItem="Risultati Elezioni 2022"
          />

          <div style={{ width: "100%", heigth: "38px", display: "flex", justifyContent: "end" }}>
            {abilitaPulsanteRisultati && <button className="btn btn-primary" onClick={() => downloadExcel(abilitaPulsanteRisultati)}>Esporta risultati</button>}
            {/* {abilitaPulsanteRisultati && <CSVLink data={abilitaPulsanteRisultati} className="btn btn-primary">Scarica i risultati</CSVLink>} */}
          </div>

          <div>
            <form>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="elezione"
                    value="camera"
                    onChange={(e) => setElezione(e.target.value)}
                  />
                  <label className="ms-1">Camera</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="elezione"
                    value="senato"
                    onChange={(e) => setElezione(e.target.value)}
                  />
                  <label className="ms-1">Senato</label>
                </div>
              </div>
            </form>
          </div>



          <div className="mb-4 ">
            <Row>

              {/* Area */}
              <Col md={6} xl={2}>
                <select
                  onChange={(e) => setArea(e.target.value)}
                  className="form-select mb-1"
                  disabled={!elezione}
                  >
                    <option hidden name="default" value="">
                      Seleziona l'area
                    </option>
                    <option name="italia" value="italia">
                      Italia
                    </option>
                    <option name="valle d'aosta" value="VALLE D'AOSTA">
                      Valle D'Aosta
                    </option>
                </select>      
              </Col>

              {/* Tipologia Collegio */}

              <Col md={6} xl={2}>
                <select
                  className="form-select mb-1"
                  disabled={!area || area === "VALLE D'AOSTA" }
                  onChange={(e) => setTipologiaCollegio(e.target.value)}>
                    { area !== "VALLE D'AOSTA" ? 
                      <option hidden value={"default"}>Tipologia di collegio</option> 
                      : <option hidden value="uninominale">Uninominale</option>}
                    <option value="uninominale">Uninominale</option>
                    {area !== "VALLE D'AOSTA" && <option value="plurinominale">Plurinominale</option>}
                </select>
              </Col>

              {/* Regione */}

              <Col md={6} xl={2}>
                {/* disabilitato se non si seleziona l'area */}
                <select
                  className="form-select mb-1"
                  disabled={tipologiaCollegio === "" || area === "VALLE D'AOSTA"}
                  onChange={(e) => setRegione(e.target.value)}>
                  <option hidden value={"default"}>
                    {area === "VALLE D'AOSTA" ? "VALLE D'AOSTA" : 'Regione'}
                  </option>

                  {regioniNomi?.length > 0 && area !== "VALLE D'AOSTA" &&
                    regioniNomi.map((regioneNome, i) => (
                      <option key={i} value={regioneNome}>
                        {regioneNome}{" "}
                      </option>
                    ))}
                </select>
              </Col>

              {/* Collegio */}

              <Col md={6} xl={2}>
                {/* disabilitato se non si seleziona l'area */}
                <select
                  className="form-select mb-1"
                  disabled={!regione && area !== "VALLE D'AOSTA"}
                  onChange={(e) => setCollegio(e.target.value)}>
                    <option hidden value={"default"}>
                      Collegio
                    </option>
                    {collegiNomi && collegiNomi?.length > 0 &&
                    collegiNomi.map((collegioNome, i) => (
                      <option key={i} value={collegioNome}>
                        {collegioNome}{" "}
                      </option>
                    ))}
                </select>
              </Col>

              {/* Comune */}

              <Col md={6} xl={2}>
                <select
                  disabled={!collegio}
                  onChange={(e) => setComune(e.target.value)}
                  className="form-select mb-1"
                  >
                    
                  <option hidden value={"default"}>
                  {(comuniNomi !== null && collegio) ? 'Comune' : 'Dati comuni non caricati'}
                    
                  </option>
                  {comuniNomi?.length > 0 && comuniNomi.map((comune, idx) => (
                    <option key={idx} name={comune} value={comune} >
                      {comune.toUpperCase()}
                    </option>
                  ))}
                </select>
              </Col>

              <Col md={6} xl={2}>
                <select
                  disabled={!comune}
                  onChange={(e) => setSezione(e.target.value)}
                  className="form-select mb-1">
                  <option hidden value={"default"}>
                    Sezione
                  </option>
                  {sezioniNomi?.length > 0 && sezioniNomi.map((sezione, idx) => (
                    <option key={idx} name={sezione} value={sezione}>
                      {sezione}
                    </option>
                  ))}
                </select>
              </Col>
              {(area === "VALLE D'AOSTA"  || tipologiaCollegio && regione) &&
              <Col md={6} xl={2}>
                <button className="btn btn-primary" onClick={mostraRisultati}>Mostra i dati</button>
                { loading && <Spinner className="ms-2" color="primary" size={'300px'} width="500px"/>}

              </Col>
              }
            </Row>
          </div>

          {!risultati && (
            <div className="text-center">
              <b>Seleziona "Camera" o "Senato" ed un'area di riferimento</b>
            </div>
          )}

          {risultati && (
            <>
            <Row>

              {/* Totale Elettori */}
              <Col md={6} xl={3}>
                <p>
                  Totale Elettori
                  { (elezione === 'camera') && " Camera: " }
                  { (elezione === 'senato') && " Senato: " }
                  <b>{ risultati.totaleElettori.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</b>
                </p>
              </Col>

              {/* Schede Valide */}
              <Col md={6} xl={3}>
                <p>
                  Schede Valide
                  { (elezione === 'camera') && " Camera: " }
                  { (elezione === 'senato') && " Senato: " }
                  <b>
                    { (elezione === 'camera' && risultati.datiSpoglio?.camera?.schedeNulle && risultati.datiSpoglio?.camera?.schedeBianche) && (
                      (risultati.datiAffluenza.affluenza23 - (risultati.datiSpoglio.camera.schedeNulle + risultati.datiSpoglio.camera.schedeBianche)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    )}
                    { (elezione === 'senato' && risultati.datiSpoglio?.senato?.schedeNulle && risultati.datiSpoglio?.senato?.schedeBianche) && (
                        (risultati.datiAffluenza.affluenza23 - (risultati.datiSpoglio.senato.schedeNulle + risultati.datiSpoglio.senato.schedeBianche)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    )}
                  </b>
                </p>
              </Col>

              {/* Schede Bianche */}
              <Col md={6} xl={3}>
                <p>
                  Schede Bianche
                  { (elezione === 'camera') && " Camera: " }
                  { (elezione === 'senato') && " Senato: " }
                  <b>
                    { (elezione === 'camera') && risultati.datiSpoglio?.camera?.schedeBianche?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                    { (elezione === 'senato') && risultati.datiSpoglio?.senato?.schedeBianche?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                  </b>
                </p>
              </Col>

              {/* Schede Nulle */}
              <Col md={6} xl={3}>
                <p>
                  Schede Nulle
                  { (elezione === 'camera') && " Camera: " }
                  { (elezione === 'senato') && " Senato: " }
                  <b>
                    { (elezione === 'camera') && risultati.datiSpoglio?.camera?.schedeNulle?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                    { (elezione === 'senato') && risultati.datiSpoglio?.senato?.schedeNulle?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
                  </b>
                </p>
              </Col>

            </Row>
            </>
          )}

          {risultati && (
            <>
            <Row>
              <Col md={6} xl={4}>
                <Card className="p-2">
                  <div className="text-center">
                    <h5>Affluenza ore 12.00</h5>
                  </div>
                  {risultati.datiAffluenza.affluenza12 && (
                    <Doughnut
                      elettori={risultati.totaleElettori}
                      votanti={risultati.datiAffluenza.affluenza12}
                    />
                  )}
                  <p>
                    Totale Elettori:
                    {risultati.totaleElettori && (
                      <span> {risultati.totaleElettori.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                  <p>
                    Votanti:{" "}
                    {risultati.datiAffluenza.affluenza12 && (
                      <span> {risultati.datiAffluenza.affluenza12.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                </Card>
              </Col>
              <Col md={6} xl={4}>
                <Card className="p-2">
                  <div className="text-center">
                    <h5>Affluenza ore 19.00</h5>
                  </div>
                  {risultati.datiAffluenza.affluenza19 && (
                    <Doughnut
                      elettori={risultati.totaleElettori}
                      votanti={risultati.datiAffluenza.affluenza19}
                    />
                  )}
                  <p>
                    Totale Elettori:
                    {risultati.totaleElettori && (
                      <span> {risultati.totaleElettori.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                  <p>
                    Votanti:{" "}
                    {risultati.datiAffluenza.affluenza19 && (
                      <span> {risultati.datiAffluenza.affluenza19.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                </Card>
              </Col>
              <Col md={6} xl={4}>
                <Card className="p-2">
                  <div className="text-center">
                    <h5>Affluenza ore 23.00</h5>
                  </div>
                  {risultati.datiAffluenza.affluenza23 && (
                    <Doughnut
                      elettori={risultati.totaleElettori}
                      votanti={risultati.datiAffluenza.affluenza23}
                    />
                  )}
                  <p>
                    Totale Elettori:
                    {risultati.totaleElettori && (
                      <span> {risultati.totaleElettori.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                  <p>
                    Votanti:
                    {risultati.datiAffluenza.affluenza23 && (
                      <span> {risultati.datiAffluenza.affluenza23.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </span>
                    )}
                  </p>
                </Card>
              </Col>
            </Row>
            <div className="mt-5"> </div>
            </>
          )}


          {(risultati && elezione) &&
            <Risultati risultati={risultati} elezione={elezione} comune={comune} camera={elezione} />
          }
          <Row>
              <Col sm={12}>
                  <a href="https://fdl-bucket.s3.eu-west-1.amazonaws.com/spoglio/xlsx/risultati.xlsx" target="_blank" rel="noreferrer">
                  <Button color="primary">Scarica i risultati di tutta Italia</Button>
                  </a>              
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dati
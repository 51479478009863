import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useHistory } from "react-router-dom";

//Verification code package
import AuthCode from "react-auth-code-input";

//import images
import logo from "../../assets/images/fdi.png";
import { loginOtp } from '../../helpers/fakebackend_helper';
import { number } from 'yup';
import axios from 'axios'
const TwoStepVerfication = () => {
    const [otp, setOtp] = useState();
    const id = JSON.parse(localStorage.getItem('id'))
    const email = JSON.parse(localStorage.getItem('email'))


    let history = useHistory();

    const data = {
        userId: id,
        otpCode: parseInt(otp)
    }

    const handleOnChange = (res) => {
        setOtp(res);
    };

    function submit() {
        loginOtp(data)
            .then(
                (response) => {
                    localStorage.setItem("token", JSON.stringify(response.data.token))
                    localStorage.setItem("user", JSON.stringify(response.data.userData))
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
                    window.location.replace('/dashboard')
                }
            )
            .catch((res) => console.log(res.message))
    }

    //aggiungere toast messaggio errore

    return (
        < React.Fragment >
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0 justify-content-center">
                        <Col lg={4} md={5} className="col-xxl-4">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="70" />
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">

                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title rounded-circle bg-light">
                                                        <i className="bx bxs-envelope h2 mb-0 text-primary"></i>
                                                    </div>
                                                </div>
                                                <div className="p-2 mt-4">

                                                    <h4>Verifica accesso</h4>
                                                    <p className="mb-5">Inserisci il codice di 6 cifre <br />
                                                        ricevuto all'indirizzo <span className="fw-bold"> {email} </span>
                                                        {/* eliminare commento quando Damiano aggiunge il campo mail nella risposta al login
                                                        togliere commento anche in auth>login>saga */}
                                                    </p>

                                                    <form>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="mb-3 verification">
                                                                    <label htmlFor="digit1-input" className="visually-hidden">Dight 1</label>
                                                                    <AuthCode
                                                                        characters={6}
                                                                        className="form-control form-control-lg text-center"
                                                                        allowedCharacters="^[0-9]"
                                                                        inputStyle={{
                                                                            width: "50px",
                                                                            height: "42px",
                                                                            padding: "8px",
                                                                            borderRadius: "8px",
                                                                            fontSize: "16px",
                                                                            textAlign: "center",
                                                                            marginRight: "15px",
                                                                            border: "1px solid #ced4da",
                                                                            textTransform: "uppercase",
                                                                        }}
                                                                        onChange={handleOnChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <div className="mt-4">
                                                        <button className="btn btn-primary w-100" onClick={() => submit()}>Invia</button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">Non hai ricevuto l'email ? <Link to="/login"
                                                    className="text-primary fw-semibold"> Invia di nuovo </Link> </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© 2022 BusinessChangers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default TwoStepVerfication;
import React, { useState, useEffect } from "react"
import RegistrationForm from "../../components/Common/RegistrationForm"
import { toast } from "react-toastify"
import { getRegioni, postSignup } from "../../helpers/fakebackend_helper"

export default function RegisterPresidente() {
  const [regioni, setRegioni] = useState(null)
  const [regione, setRegione] = useState("")
  const role = "Presidente Regionale"

  const handleOnSubmit = (inputsData) => {
    if (regione)
      postSignup({ ...inputsData, regione, role })
        .then(() => window.location.replace("/page-register-verification"))
        .catch((err) => toast.error(err.response.data.message))
  }

  const handleFetchRegioni = () => {
    getRegioni({ params: { role } })
      .then((res) => {
        setRegioni(res)
      })
      .catch((err) => toast.error(err.message))
  }

  useEffect(() => handleFetchRegioni(), [])

  return (
    <>
      <RegistrationForm
        handleOnSubmit={handleOnSubmit}
        roleForm={
          <>
            <div className="mb-3" style={{ marginTop: "40px" }}>
              {/* <h6>Seleziona la tua area di appartenenza</h6> */}
              <select
                key="reg"
                id="regione"
                className="form-select mb-3"
                name="regione"
                defaultValue={"default"}
                onChange={(e) => setRegione(e.target.value)}>
                <option
                  value="default"
                  label="Seleziona una regione"
                  key="regionedef"
                  hidden></option>
                {regioni &&
                  regioni.map((regione, i) => (
                    <option
                      key={i}
                      name={regione}
                      label={regione.toUpperCase()}
                      value={regione}>
                      {regione}
                    </option>
                  ))}
              </select>
            </div>
          </>
        }
      />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Modal } from "reactstrap";
import { toast } from 'react-toastify';
import { refuseUser } from '../../helpers/fakebackend_helper';


const EliminaUser = ({modal_eliminaUser, setmodal_eliminaUser, user }) => {


    function tog_eliminaUser() {
        setmodal_eliminaUser(!modal_eliminaUser)
      }


      function cancella () {
        refuseUser({userId: user._id})
        .then((res) =>  {
          toast.success(res.message)
           setmodal_eliminaUser(!modal_eliminaUser)
        })
        .catch((err) => {
          toast.error(err.response.data.message)
           setmodal_eliminaUser(!modal_eliminaUser)
        })

        //console.log('click qui')
    }

  return (
    <Modal size="xl" isOpen={modal_eliminaUser} toggle={() => {tog_eliminaUser()}}>
    <div className="modal-header">
      <h5 className="modal-title mt-0" id="modaleliminauser">
        Elimina Utente
      </h5>
      <button onClick={() => {setmodal_eliminaUser(false)}}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div className="modal-body">
      <p>
       Vuoi eliminare l'utente <b>{user.firstName} {user.lastName}</b>? <br/>
       Per confermare clicca su <b>Elimina</b>. Per annullare l'operazione clicca su <b>Annulla</b>.
      </p>
    </div>

    <div className="modal-footer">
      <button
        type="button"
        onClick={tog_eliminaUser}
        className="btn btn-secondary "
        data-dismiss="modal"
      >
        Annulla
      </button>
      
        <button type="button" className="btn btn-danger" onClick={cancella}>
          Elimina <i className="mdi mdi-close-thick"/>
        </button>
     
    </div>
  </Modal>
  )
}

export default EliminaUser
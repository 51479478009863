import React from "react"
import ReactEcharts from "echarts-for-react"

function Doughnut({ elettori, votanti }) {
  function getOption() {
    return {
      toolbox: {
        show: !1,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["Elettori", "Votanti", "Mobile", "Others", "Desktop"],
        textStyle: {
          color: ["#858d98"],
        },
      },
      color: ["#5156be", "#ffbf53", "#fd625e", "#4ba6ef", "#2ab57d"],
      series: [
        {
          name: "Totale",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: !1,
          label: {
            normal: {
              show: !1,
              position: "center",
            },
            emphasis: {
              show: !0,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: !1,
            },
          },
          data: [
            // Elettori = totaleElettori - totaleVotanti
            { value: elettori - votanti, name: "Elettori" },
            { value: votanti, name: "Votanti" },
          ],
        },
      ],
    }
  }

  return <ReactEcharts style={{ height: "350px" }} option={getOption()} />
}
export default Doughnut

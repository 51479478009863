import React, { useEffect, useState } from "react"
import { Container, Card, Col, Row, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import FeatherIcon from "feather-icons-react"

import img2 from "../../assets/images/small/img-2.jpg"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getIssues } from "../../helpers/fakebackend_helper"

const Criticità = () => {
  const [issues, setIssues] = useState([])

  useEffect(() => {
    getIssues()
      .then((response) => {
        setIssues(response)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Criticità" breadcrumbItem="Criticità" />
          {/* LISTA CRITICITA' - ACCESSO: ??? */}
          {/* MAPPARE LISTA TUTTE CRITICITA */}
          {issues.length > 0 ? (
            issues.map((issue) => (
              <>
                <Card key={issue._id}>
                  <Row className="g-0 align-items-center">
                    <Col md={8}>
                      <CardBody>
                        <h5 className="card-title">{issue.title} </h5>
                        <h6>{issue.subtitle}</h6>
                        <p className="card-text">{issue.description}</p>
                        <p>
                          <small>Status segnalazione: {issue.status}</small>
                        </p>
                        <p className="card-text">
                          <small className="text-muted">
                            Data segnalazione: <span> </span>
                            {new Intl.DateTimeFormat("it-IT", {
                              dateStyle: "full",
                              timeStyle: "short",
                            }).format(new Date(issue.createdAt))}
                          </small>
                        </p>
                        <Link
                          to={`/criticita/${issue._id}`}
                          key={issue._id}
                          className="btn btn-primary waves-effect waves-light">
                          Visualizza
                        </Link>
                        {issue.attachments &&
                        <a
                          href={issue.attachments}
                          download
                          style={{ marginLeft: "10px" }}>
                          <b>Download file</b>
                          <FeatherIcon
                            icon="download"
                            style={{ marginLeft: "10px" }}
                          />
                        </a>}
                      </CardBody>
                    </Col>

                  </Row>
                </Card>
              </>
            ))
          ) : (
            <h3>Non sono presenti segnalazioni.</h3>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Criticità

import React, { useState, useEffect } from "react"
import { Container, Label, Input, Form, FormFeedback } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  getRegioni,
  getProvince,
  getComuni,
  postNewUser,
} from "../../helpers/fakebackend_helper"

//PAGINA VISIBILE SOLO AL PARTITO CENTRALE E AI PRESIDENTI REGIONALI PER POTER ISCRIVERE NUOVI UTENTI
const NuoviUtenti = () => {
  document.title = "Iscrivi Utenti | Gestione Rappresentanti di Lista"

  const [role, setRole] = useState("")
  const [regioni, setRegioni] = useState([])
  const [prov, setProv] = useState([])
  const [comuni, setComuni] = useState([])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      role: "",
      regione: "",
      provincia: "",
      comune: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required(
        "Il campo è obbligatorio. Inserisci un indirizzo email."
      ),
      firstName: Yup.string().required(
        "Il campo è obbligatorio. Inserisci il nome dell'utente."
      ),
      lastName: Yup.string().required(
        "Il campo è obbligatorio. Inserisci il cognome dell'utente."
      ),
    }),
    onSubmit: (values) => {
      if (
        values.role === "Coordinatore Provinciale" &&
        (!values.regione || !values.provincia)
      ) {
        return console.log("Devi selezionare una regione e una provincia")
      }
      if (
        values.role ===
          ("Coordinatore Comunale" || "Rappresentante di lista") &&
        (!values.regione || !values.provincia || !values.comune)
      ) {
        return console.log(
          "Devi selezionare una regione, una provincia e un comune"
        )
      }
      postNewUser(values)
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
    },
  })

  useEffect(() => {
    getRegioni().then((res) => setRegioni(res))
  }, [])

  useEffect(() => {
    validation.values.regione = ""
    validation.values.provincia = ""
    validation.values.comune = ""
  }, [validation.values.role])

  useEffect(() => {
    if (validation.values.regione)
      getProvince({
        params: { regione: validation.values.regione, role },
      }).then((res) => setProv(res))
  }, [validation.values.regione])

  useEffect(() => {
    if (validation.values.provincia)
      getComuni({
        params: { provincia: validation.values.provincia, role },
      }).then((res) => setComuni(res))
  }, [validation.values.provincia])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Utenti" breadcrumbItem="Iscrivi Nuovi Utenti" />

        <h3>Compila il form sottostante per iscrivere nuovi utenti.</h3>
        <h5>
          Gli utenti riceveranno all'indirizzo email indicato le credenziali per
          accedere alla piattaforma.
        </h5>

        <Form
          className="needs-validation custom-form mt-4 pt-2"
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}>
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              id="email"
              name="email"
              className="form-control"
              placeholder="Inserisci Email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3 ">
            <Label className="form-label">Nome</Label>
            <Input
              name="firstName"
              type="text"
              placeholder="Inserisci Nome"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName || ""}
              invalid={
                validation.touched.firstName && validation.errors.firstName
                  ? true
                  : false
              }
            />
            {validation.touched.firstName && validation.errors.firstName ? (
              <FormFeedback type="invalid">
                {validation.errors.firstName}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Cognome</Label>
            <Input
              name="lastName"
              type="text"
              placeholder="Inserisci Cognome"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName || ""}
              invalid={
                validation.touched.lastName && validation.errors.lastName
                  ? true
                  : false
              }
            />
            {validation.touched.lastName && validation.errors.lastName ? (
              <FormFeedback type="invalid">
                {validation.errors.lastName}
              </FormFeedback>
            ) : null}
          </div>

          {/* ruolo */}
          <div className="mb-3">
            <Label className="form-label fs-5">Seleziona un Ruolo:</Label>
            <div>
              <label className="container ">
                <input
                  type="radio"
                  name="role"
                  value="Coordinatore Provinciale"
                  onChange={(e) => {
                    validation.handleChange(e)
                    setRole(e.target.value)
                  }}
                  disabled={
                    !validation.values.email ||
                    !validation.values.firstName ||
                    !validation.values.lastName
                  }
                />
                <span className="mx-2 align-text-bottom">
                  Coordinatore provinciale
                </span>
                <span className="checkmark"></span>
              </label>
              <label className="container">
                <input
                  type="radio"
                  name="role"
                  value="Coordinatore Comunale"
                  onChange={(e) => {
                    validation.handleChange(e)
                    setRole(e.target.value)
                  }}
                  disabled={
                    !validation.values.email ||
                    !validation.values.firstName ||
                    !validation.values.lastName
                  }
                />
                <span className="mx-2 align-text-bottom">
                  Coordinatore comunale
                </span>
                <span className="checkmark"></span>
              </label>

              <label className="container">
                <input
                  type="radio"
                  name="role"
                  value="Rappresentante di Lista"
                  onChange={(e) => {
                    validation.handleChange(e)
                    setRole(e.target.value)
                  }}
                  disabled={
                    !validation.values.email ||
                    !validation.values.firstName ||
                    !validation.values.lastName
                  }
                />
                <span className="mx-2 align-text-bottom">
                  Rappresentante di lista
                </span>
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          {/* REGIONE */}
          <div className="mb-3">
            <select
              key="reg"
              id="regione"
              className="form-select mb-3"
              name="regione"
              onBlur={validation.handleBlur}
              value={validation.values.regione || ""}
              defaultValue={"default"}
              onChange={validation.handleChange}>
              <option
                value={"default"}
                label="Seleziona una regione"
                key="regionedef"
                hidden>
                Seleziona una regione
              </option>
              {regioni.length > 0 &&
                regioni.map((regione, i) => (
                  <option
                    key={i}
                    name={regione}
                    onChange={validation.handleChange}
                    label={regione.toUpperCase()}
                    value={regione}>
                    {regione}{" "}
                  </option>
                ))}
            </select>

            {/* PROVINCIA */}
            <select
              key="prov"
              id="provincia"
              className="form-select mb-3"
              name="provincia"
              onBlur={validation.handleBlur}
              value={validation.values.provincia || ""}
              defaultValue={"default"}
              onChange={validation.handleChange}>
              <option
                value={"default"}
                label="Seleziona una provincia"
                key="regionedef"
                hidden>
                Seleziona una provincia
              </option>
              {prov.length > 0 &&
                prov.map((provincia, i) => (
                  <option
                    key={i}
                    name={provincia}
                    onChange={validation.handleChange}
                    label={provincia.toUpperCase()}
                    value={provincia}>
                    {provincia}{" "}
                  </option>
                ))}
            </select>

            {/* COMUNE */}
            {validation.values.role !== "Coordinatore Provinciale" && (
              <select
                key="com"
                id="comune"
                className="form-select mb-3"
                name="comune"
                onBlur={validation.handleBlur}
                value={validation.values.comune || ""}
                defaultValue={"default"}
                onChange={validation.handleChange}>
                <option
                  value={"default"}
                  label="Seleziona un comune"
                  key="regionedef"
                  hidden>
                  Seleziona un comune
                </option>
                {comuni.length > 0 &&
                  comuni.map((comune, i) => (
                    <option
                      key={i}
                      name={comune}
                      onChange={validation.handleChange}
                      label={comune.toUpperCase()}
                      value={comune}>
                      {comune}{" "}
                    </option>
                  ))}
              </select>
            )}
          </div>

          <small>* Tutti i campi sono obbligatori.</small>

          <div className="mb-3">
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit">
              Iscrivi utente
            </button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default NuoviUtenti

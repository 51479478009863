import React, { useState, useRef } from "react"
import {
  Container,
  Button,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify';



//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { newIssue } from "../../helpers/fakebackend_helper"

const Segnala = () => {
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectedFiles, setSelectedFile] = useState(null)


  let history = useHistory()

  function handleOnLoadFile(file) {
    if (file.size > 5 * 1000 * 1000)
      return console.log("Il file selezionato è troppo grande")
    setSelectedFile(file)
  }

  const imgRef = useRef(null)

  // const config = {
  //   headers: {
  //     "content-type": "multipart/form-data",
  //   },
  // }

  // const data = new FormData()
  // data.append('title', title);
  // data.append('subtitle', subtitle);
  // data.append('description', description);
  // data.append('attachments', selectedFiles);

  // let data = {
  //   title:  title ,
  //   subtitle:  subtitle,
  //   description:  description,
  //   attachments: selectedFiles
  // };

  function addIssue() {
    const formData = new FormData()
    formData.append("attachments", selectedFiles)
    formData.append("title", title)
    formData.append("description", description)

    newIssue(formData)
      .then((res) => {
        setTimeout(() => {
          history.push("/dashboard")
        }, 3000),
        toast.success(res.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Criticità" breadcrumbItem="Segnala Criticità" />
          {/* SEGNALA CRITICITA' - ACCESSO: RAPPRESENTANTE DI LISTA */}
         
          <h3 className="mb-3">Compila il form per fare una segnalazione.</h3>
          <form action="javascript:void(0);">
            <div className="mb-3">
              <label className="form-label" htmlFor="formrow-title-input">
                Titolo*
              </label>
              <input
                name="title"
                type="text"
                className="form-control"
                id="formrow-title-input"
                placeholder="Titolo"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

           
            <div className="mb-3">
              <label className="form-label" htmlFor="formrow-description-input">
                Descrizione*
              </label>
              {/* <input
                name="description"
                type="textarea"
                className="form-control"
                id="formrow-description-input"
                placeholder="Descrizione"
                onChange={(e) => setDescription(e.target.value)}
              /> */}
              <textarea
              name="description"
              type="textarea"
              className="form-control"
              id="formrow-description-input"
              placeholder="Descrizione"
              onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div>
              <input
                id="selectedFiles"
                name="selectedFiles"
                ref={imgRef}
                type="file"
                onChange={(e) => handleOnLoadFile(e.target.files[0])}
              />
            </div>

            <Button
              className="btn bg-primary mt-3"
              onClick={() => addIssue()}
              disabled={!title || !description}>
              Segnala
            </Button>
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Segnala

import React, { useState, useEffect } from "react";
import { Container, Col, Card, Row, Modal, Input } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DatiVoti from "./DatiVoti";
import { getSezione, getDatiSezione, getListePerSezione, postSchedeBianche, postSchedeNulle, postAffluenza, postTotaleElettori } from "../../helpers/fakebackend_helper";
const InserimentoDati = () => {
  document.title = "Inserimento Dati Spoglio | Gestione Rappresentanti di Lista";

  const [modal_elettori, setmodal_elettori] = useState(false);
  const [modal_votanti12, setmodal_votanti12] = useState(false);
  const [modal_votanti19, setmodal_votanti19] = useState(false);
  const [modal_votanti23, setmodal_votanti23] = useState(false);

  const loggedUser = JSON.parse(localStorage.getItem("user"))

  const [schedeBianche, setSchedeBianche] = useState(null)
  const [schedeNulle, setSchedeNulle] = useState(null)

  function tog_elettori() {
    setmodal_elettori(!modal_elettori)
  }

  const [elezione, setElezione] = useState("camera")
  const [collPluriNomi, setCollPluriNomi] = useState(null)
  const [collUniNomi, setCollUniNomi] = useState(null)
  const [section, setSection] = useState([])
  const [selectedSection, setSelectedSection] = useState(null)
  const [sectionDef, setSectionDef] = useState(null)

  const [affluenza, setAffluenza] = useState(null)
  const [ora, setOra] = useState(null)
  const [totaleElettori, setTotaleElettori] = useState(null)
  const [datiSezione, setDatiSezione] = useState(null)

  function updateWarningMessage() {
    getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
      .then((res) => setDatiSezione(res))
  }

  function postaSchedeBianche() {
    const params = {
      camera: elezione,
      comune: loggedUser.comune.toLowerCase(),
      sezione: sectionDef[0]
    }
    postSchedeBianche({ params }, { "schedeBianche": parseInt(schedeBianche) })
      .then((res) => {
        toast.success(res.message)
        getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
          .then((res) => {
            setDatiSezione(res)
          })
      })
      .catch((err) => toast.error(err.response.data.message))

  }

  function postaSchedeNulle() {
    const params = {
      camera: elezione,
      comune: loggedUser.comune.toLowerCase(),
      sezione: sectionDef[0]
    }
    postSchedeNulle({ params }, { "schedeNulle": parseInt(schedeNulle) })
      .then((res) => {
        toast.success(res.message)
        getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
          .then((res) => {
            setDatiSezione(res)
          })
      })
      .catch((err) => toast.error(err.response.data.message))
  }

  function postaAffluenza() {
    const data = {
      comune: loggedUser.comune.toLowerCase(),
      sezione: parseInt(sectionDef),
      affluenza: parseInt(affluenza),
      ora: ora
    }
    postAffluenza(data)
      .then((res) => {
        toast.success(res.message)
        getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
          .then((res) => {
            setDatiSezione(res)
          })
      })
      .catch((err) => toast.error(err.response.data.message))
  }

  function postaTotaleElettori() {
    const data = {
      comune: loggedUser.comune.toLowerCase(),
      sezione: parseInt(sectionDef[0]),
      totaleElettori: parseInt(totaleElettori)
    }
    postTotaleElettori(data)
      .then((res) => {
        toast.success(res.message)
        getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
          .then((res) => {
            setDatiSezione(res)
          })
      })
      .catch((err) => toast.error(err.response.data.message))
  }

  function handleVotanti12(e) {
    setOra(12)
    setAffluenza(e.target.value)
  }

  function handleVotanti19(e) {
    setOra(19)
    setAffluenza(e.target.value)
  }

  function handleVotanti23(e) {
    setOra(23)
    setAffluenza(e.target.value)
  }

  function handleChangeElezione() {
    const params = {
      camera: elezione,
      comune: loggedUser.comune.toLowerCase(),
      sezione: sectionDef[0]
    }
    getDatiSezione({ params: { comune: loggedUser.comune.toLowerCase(), sezione: sectionDef[0] } })
      .then((res) => {
        setDatiSezione(res)
      })

    getListePerSezione({ params })
      .then((res) => {
        setCollPluriNomi(res.plurinominale)
        setCollUniNomi(res.uninominale)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (elezione && sectionDef) handleChangeElezione()
  }, [elezione, sectionDef])

  useEffect(() => {
    console.log(datiSezione)
  }, [datiSezione])

  useEffect(() => {
    getSezione()
      .then(res => {
        setSection(res.sezioni)
        if (res.sezioni.length > 1) setSectionDef([res.sezioni[0]])
        else setSectionDef([res.sezioni])
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Spoglio"
            breadcrumbItem="Inserimento Dati Spoglio"
          />
          {/* inserimento DATI SPOGLIO  - ACCESSO: RAPPRESENTANTI DI LISTA */}
          <div className="mb-4 " style={{ borderRadius: "20px", background: "#eeeeee80", padding: "30px" }}>
            <form>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="elezione"
                    value="camera"
                    checked={elezione === "camera"}
                    onChange={e => setElezione(e.target.value)}
                  />
                  <label className="ms-1">Camera</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="elezione"
                    value="senato"
                    onChange={e => setElezione(e.target.value)}
                  />
                  <label className="ms-1">Senato</label>
                </div>
              </div>
            </form>
            <Row>
              <Col md={6} xl={2}>
                <div style={{ paddingTop: "5px" }} className="d-flex align-items-center">
                  <i className="fas fa-hotel"></i>
                  <strong style={{ marginLeft: "15px" }}>{loggedUser.comune}</strong>
                </div>
              </Col>
              <Col className="d-flex align-items-center" md={6} xl={2}>
                {section.length < 2 ?
                  section.map((elem, index) => {
                    return (
                      <strong className="d-flex align-items-center" key={index}>Sezione: {elem}  </strong>
                    )
                  })
                  :
                  <select
                    className="form-select mb-1"
                    onChange={(e) => {
                      setSelectedSection(e.target.value)
                      setSectionDef(e.target.value)
                    }}>
                    <option hidden value={"default"}>
                      Sezione
                    </option>
                    {section.map((elem, index) => {
                      return (
                        <option key={index} name="italia" value={elem}>
                          {elem}
                        </option>
                      )
                    })}
                  </select>
                }
              </Col>
            </Row>
          </div>
          {(!datiSezione?.totaleElettori ||
            !datiSezione?.datiAffluenza?.affluenza12 ||
            !datiSezione?.datiAffluenza?.affluenza19 ||
            !datiSezione?.datiAffluenza?.affluenza23 ||
            !datiSezione?.datiSpoglio?.[elezione]?.schedeBianche ||
            !datiSezione?.datiSpoglio?.[elezione]?.schedeNulle ||
            !datiSezione?.datiSpoglio?.[elezione]?.schedeValide?.plurinominale ||
            !datiSezione?.datiSpoglio?.[elezione]?.schedeValide?.uninominale) &&
            <div className="mb-4 " style={{ borderRadius: "20px", background: "#e5737380", padding: "30px" }}>
              <Row>
                <h4>Dati Mancanti </h4>
                <p>Attenzione, ricordati di inserire i seguenti dati per validare il caricamento dei dati di spoglio: </p>
                {datiSezione && !datiSezione?.totaleElettori && <p>- Totale Elettori</p>}
                {datiSezione && !datiSezione?.datiAffluenza?.affluenza12 && <p>- Affluenza ore 12</p>}
                {datiSezione && !datiSezione?.datiAffluenza?.affluenza19 && <p>- Affluenza ore 19</p>}
                {datiSezione && !datiSezione?.datiAffluenza?.affluenza23 && <p>- Affluenza ore 23</p>}
                {datiSezione && !datiSezione?.datiSpoglio?.[elezione]?.schedeBianche && <p>- Numero Schede Bianche</p>}
                {datiSezione && !datiSezione?.datiSpoglio?.[elezione]?.schedeNulle && <p>- Numero Schede Nulle</p>}
                {datiSezione && !datiSezione?.datiSpoglio?.[elezione]?.schedeValide?.uninominale && <p>- Voti collegio uninominale</p>}
                {datiSezione && !datiSezione?.datiSpoglio?.[elezione]?.schedeValide?.plurinominale && <p>- Voti collegio plurinominale</p>}
              </Row>
            </div>
          }
          <Card className="p-3">
            <h3>Inserimento dati Affluenza</h3>
            <p>I dati fanno riferimento alle sezioni di appartenenza</p>
            <Row>
              <Col md={6} xl={8}>
                <Card className="p-3 mb-2" style={{ height: "100%" }}>
                  <div className="d-flex">
                    <div className="ms-3">
                      <FeatherIcon icon="map-pin" />
                    </div>
                    <div className="d-flex justify-content-between ms-3 ">
                      <div className="text-muted">
                        <h5>Informazioni selezionate:</h5>
                        {elezione && <p>{elezione.toUpperCase()}</p>}
                        {sectionDef && <p className="mb-1"><b>Sezione:</b> {sectionDef}</p>}
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>

              {section && elezione &&
                <Col md={6} xl={4}>
                  <Card className="p-3" style={{ height: "100%" }}>
                    <h5>Inserisci il numero totale degli elettori</h5>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Totale Elettori"
                      defaultValue={datiSezione && datiSezione?.totaleElettori}
                      onChange={(e) => setTotaleElettori(e.target.value)}
                      min='0'
                    />
                    <button className="btn btn-primary" onClick={postaTotaleElettori}>Conferma</button>
                  </Card>
                </Col>
              }
            </Row>
            {section && elezione &&
              <Row className="mt-4">
                <Col md={6} xl={4}>
                  <Card className="p-3">
                    <div className="text-center">
                      <h5>Affluenza ore 12.00</h5>
                    </div>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Inserisci Votanti"
                      defaultValue={datiSezione && datiSezione?.datiAffluenza?.affluenza12}
                      onChange={handleVotanti12}
                      min='0'
                    />
                    <button className="btn btn-primary " onClick={postaAffluenza}>Conferma</button>
                  </Card>
                </Col>

                <Col md={6} xl={4}>
                  <Card className="p-3">
                    <div className="text-center">
                      <h5>Affluenza ore 19.00</h5>
                    </div>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Inserisci Votanti"
                      onChange={handleVotanti19}
                      defaultValue={datiSezione && datiSezione?.datiAffluenza?.affluenza19}
                      min='0'
                    />
                    <button className="btn btn-primary " onClick={postaAffluenza}>Conferma</button>
                  </Card>
                </Col>

                <Col md={6} xl={4}>
                  <Card className="p-3">
                    <div className="text-center">
                      <h5>Affluenza ore 23.00 (chiusura seggi)</h5>
                    </div>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Inserisci Votanti"
                      defaultValue={datiSezione && datiSezione?.datiAffluenza?.affluenza23}
                      onChange={handleVotanti23}
                      min='0'
                    />
                    <button className="btn btn-primary " onClick={postaAffluenza}>Conferma</button>
                  </Card>
                </Col>
              </Row>}

            {section && elezione &&
              <Row className="mt-4">
                <Col md={12}>
                  <h3>Conteggio schede ({elezione})</h3>
                </Col>
                <Col md={6} xl={4}>
                  <Card className="p-3">
                    <div className="text-center">
                      <h5>Schede Bianche</h5>
                    </div>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Inserisci Numero Schede Bianche"
                      onChange={(e) => setSchedeBianche(e.target.value)}
                      defaultValue={datiSezione && datiSezione?.datiSpoglio?.[elezione]?.schedeBianche}
                    />
                    <button className="btn btn-primary " onClick={postaSchedeBianche}>Conferma</button>
                  </Card>
                </Col>

                <Col md={6} xl={4}>
                  <Card className="p-3">
                    <div className="text-center">
                      <h5>Schede Nulle</h5>
                    </div>
                    <input
                      className="form-control mb-2"
                      type="number"
                      placeholder="Inserisci Numero Schede Nulle"
                      onChange={(e) => setSchedeNulle(e.target.value)}
                      defaultValue={datiSezione && datiSezione?.datiSpoglio?.[elezione]?.schedeNulle}
                    />
                    <button className="btn btn-primary " onClick={postaSchedeNulle}>Conferma</button>
                  </Card>
                </Col>
              </Row>
            }


            {/* modal conferma inserimento elettori */}
            <Modal
              isOpen={modal_elettori}
              toggle={() => {
                tog_elettori();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Vuoi confermare questo elemento?
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_elettori(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Clicca su <strong>Conferma</strong> per inserire il numero di
                  elettori. <br />
                  Per annullare l'operazione, clicca su <strong>Annulla</strong>
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_elettori();
                  }}
                  className="btn btn-primary "
                  data-dismiss="modal"
                >
                  Annulla
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                // onClick={() => INSERIRE QUI CHIAMATA PER AGG TOT ELETTORI}
                >
                  Conferma
                </button>
              </div>
            </Modal>

            {/* modal conferma inserimento votanti 12 */}
            <Modal
              isOpen={modal_votanti12}
              toggle={() => {
                tog_votanti12;
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Vuoi confermare questo elemento?
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_votanti12(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Clicca su <strong>Conferma</strong> per inserire il numero di
                  votanti12. <br />
                  Per annullare l'operazione, clicca su <strong>Annulla</strong>
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_votanti12();
                  }}
                  className="btn btn-primary "
                  data-dismiss="modal"
                >
                  Annulla
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                // onClick={() => INSERIRE QUI CHIAMATA PER AGG TOT ELETTORI}
                >
                  Conferma
                </button>
              </div>
            </Modal>

            {/* modal conferma inserimento votanti 19 */}
            <Modal
              isOpen={modal_votanti19}
              toggle={() => {
                tog_votanti19();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Vuoi confermare questo elemento?
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_votanti19(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Clicca su <strong>Conferma</strong> per inserire il numero di
                  elettori. <br />
                  Per annullare l'operazione, clicca su <strong>Annulla</strong>
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_votanti19();
                  }}
                  className="btn btn-primary "
                  data-dismiss="modal"
                >
                  Annulla
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                // onClick={() => INSERIRE QUI CHIAMATA PER AGG TOT ELETTORI}
                >
                  Conferma
                </button>
              </div>
            </Modal>

            {/* modal conferma inserimento votanti 23 */}
            <Modal
              isOpen={modal_votanti23}
              toggle={() => {
                tog_votanti23();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Vuoi confermare questo elemento?
                </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_votanti23(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Clicca su <strong>Conferma</strong> per inserire il numero di
                  elettori. <br />
                  Per annullare l'operazione, clicca su <strong>Annulla</strong>
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_votanti23();
                  }}
                  className="btn btn-primary "
                  data-dismiss="modal"
                >
                  Annulla
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                // onClick={() => INSERIRE QUI CHIAMATA PER AGG TOT ELETTORI}
                >
                  Conferma
                </button>
              </div>
            </Modal>
          </Card>


          {/* INSERIRE QUI COMPONENTE INSERIMENTO DATI SPOGLIO VOTI */}
          {collUniNomi && collPluriNomi && sectionDef &&
            <Card className="p-3 mt-5">
              <DatiVoti
                collUni={collUniNomi}
                collegio_plurinominale={collPluriNomi}
                camera={elezione}
                comune={loggedUser.comune.toLowerCase()}
                sezione={sectionDef}
                datiSezione={datiSezione}
                updateWarningMessage={updateWarningMessage}
              />
            </Card>
          }

        </Container>
      </div>
    </React.Fragment>
  );
};

export default InserimentoDati;
import React from 'react'
import { Container } from "reactstrap";
import { Table, Card, CardBody, CardHeader, Input } from "reactstrap";
import { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
//import { toast } from 'react-toastify'
//import style from '../DatiSpoglio/style.module.scss'
import PaginationCustom from "./Pagination";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

// actions
import { approveUser, refuseUser, fetchAllUsers, fetchListaUtentiInBaseAlRuolo  } from "../../helpers/fakebackend_helper";
import ModificaUser from "./ModificaUser";
import EliminaUser from "./EliminaUser";

const ListaRappresentanti = () => {
     //meta title
  document.title = "Visualizza Rappresentanti di Lista | Gestione Rappresentanti di Lista";

  const [user, setUser] = useState(null);
  const [modal_modificaUser, setmodal_modificaUser] = useState(false)
  const [modal_eliminaUser, setmodal_eliminaUser] = useState(false)

  const [users, setUsers] = useState([]);

  const token = JSON.parse(localStorage.getItem('token'))

  function tog_modificaUser(user) {
    setUser(user)
    setmodal_modificaUser(!modal_modificaUser)
  }

  function tog_eliminaUser(user) {
    setUser(user)
    setmodal_eliminaUser(!modal_eliminaUser)
  }


  useEffect(() => {
    // parametri per il fetch
      if (!modal_eliminaUser && !modal_modificaUser && token) {
        fetchListaUtentiInBaseAlRuolo()
          .then((res) => {
            console.log(res)
            setUsers(res)
            //setTotPages(res.pages)
          })
          .catch((err) => console.log(err));
      }}, [modal_modificaUser])



  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title="Utenti"
          breadcrumbItem="Visualizza gli Utenti del tuo territorio"
        />
        {/* LISTA DI TUTTI I RAPPRESENTANTI*/}

        {users?.length < 1 ? <h3>Nessun utente trovato</h3> : (
          <>
            <Card>
              <div className="table-responsive">
                <CardHeader>
                  <h4 className="card-title">Lista Utenti</h4>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Cognome</th>
                          <th>Ruolo</th>
                          <th>Telefono</th>
                          <th>Email</th>
                          <th>Regione</th>
                          <th>Provincia</th>
                          <th>Comune</th>
                          <th>Sezione</th>
                          <th>Modifica</th>
                          <th>Elimina</th>
                        </tr>
                      </thead>


                      {users &&
                        users.map((user) => (
                          <>
                            <tbody key={user._id}>
                              <tr>
                                <td>{user.firstName} </td>
                                <td>{user.lastName}</td>
                                <td>{user.role}</td>
                                {user.phone ? <td>{user.phone}</td> : <td>-</td>}
                                <td>{user.email}</td>
                                {user.regione ? <td>{user.regione}</td> : <td>-</td>}
                                {user.provincia ? <td>{user.provincia}</td> : <td>-</td>}
                                {user.comune ? <td>{user.comune}</td> : <td>-</td>}
                                {(user.sezioni && user.sezioni.length > 0) ? <td>{user.sezioni.toString()}</td> : (<td>-</td>)}
                                <td><button className="btn btn-outline-primary" onClick={() => tog_modificaUser(user)}><i className="mdi mdi-lead-pencil" /></button></td>
                                <td><button className="btn btn-outline-danger" onClick={() => tog_eliminaUser(user)}><i className="mdi mdi-close-thick" /></button></td>
                              </tr>
                            </tbody>
                          </>
                        ))}
                    </Table>

                    {user &&
                      <ModificaUser modal_modificaUser={modal_modificaUser} setmodal_modificaUser={setmodal_modificaUser} user={user} />
                    }

                    {user &&
                      <EliminaUser modal_eliminaUser={modal_eliminaUser} setmodal_eliminaUser={setmodal_eliminaUser} user={user} />}

                  </div>
                </CardBody>
              </div>
            </Card>

            {/* Paginazione */}
            {/* <PaginationCustom currentPage={page} setPage={setPage} totalPages={totPages} /> */}
          </>

        )}

      </Container>
    </div>
  </React.Fragment>
  )
}

export default ListaRappresentanti
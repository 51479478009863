import React from "react";
import { Container, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCommunications } from "../../helpers/fakebackend_helper";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Comunicazione = () => {

      const [communications, setCommunications] = useState({});

      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };


      useEffect(() => {
        getCommunications()
        .then((response) => {
        setCommunications(response)
        })
        .catch(err =>
          console.log(err));
     }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Comunicazioni" breadcrumbItem="Comunicazione" />
          {/* LISTA COMUNICAZIONI. - ACCESSO: TUTTI */}

          {/* MAPPARE COMUNICAZIONI */}
          {communications.length > 0 ? communications.map((communication)=>
          <>
            <Card key={communication._id}>
            <div className="card-header bg-transparent border-bottom text-uppercase">
              {communication.title}
            </div>
            <CardBody key={communication._id}>
              <h4 className="card-title"> {communication.subtitle}</h4>
              <p className="card-text">
              {communication.description}
              </p>
              <Link to={`/comunicazione/${communication._id}`} key={communication._id} className="btn btn-primary waves-effect waves-light">
                Visualizza
              </Link>
              
            </CardBody>
            <div className="card-footer bg-transparent border-top text-muted">
           {new Intl.DateTimeFormat('it-IT', options).format(new Date(communication.updatedAt))}
            </div>
          </Card>
          </>) : 
          
          <div>
            <h3>Nessuna comunicazione presente</h3>
            </div>
          }
        
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Comunicazione;

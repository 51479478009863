import React from "react"
import { Card, Table, Row, Col } from "reactstrap"
import style from './style.module.scss'

const Risultati = ({ risultati, elezione }) => {

  let nomi = []

  let candidatiUni = []


  // iterare  nella lista partiti 
  for (let nomePartito in risultati.datiSpoglio[elezione]?.schedeValide?.plurinominale) {
    nomi.push(nomePartito);
  }

  for (let nomeCandidato in risultati.datiSpoglio[elezione]?.schedeValide?.uninominale) {
    candidatiUni.push(nomeCandidato);
  }


  return (
    <Card className="p-3 mt-3">
      <h5>Risultati Spoglio</h5>
      <Row className="mt-2">
        <Col md={6} xl={4}>
          <h4>PLURINOMINALE</h4>
          <Card>
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>Lista</th>
                  <th>Voti</th>
                </tr>
              </thead>

              {/* PLURINOMINALE */}
              <tbody>
                {risultati && nomi.map((partito, i) =>
                  <>
                    <tr key={i}>
                      <td>
                        <img src={risultati.datiSpoglio[elezione].schedeValide.plurinominale[partito].logo} style={{ maxHeight: "80px" }} />
                        <span className="ms-3">{partito} </span>

                      </td>
                      <td>
                        <p>{risultati.datiSpoglio[elezione].schedeValide.plurinominale[partito].voti.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </p>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>

        {/* UNINOMINALE */}
        <Col md={6} xl={8}>
          <h4>UNINOMINALE</h4>
          <Card>
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th>Candidato</th>
                  <th>Coalizione</th>
                  <th>Voti</th>
                </tr>
              </thead>

              <tbody>
                {risultati && candidatiUni.map((candidato, i) =>
                  <>
                    <tr key={i}>
                      <td>
                        <h5>{candidato}</h5>
                        {risultati.datiSpoglio[elezione].schedeValide.uninominale[candidato].coalizione.map((item, index) => (
                          <img key={index} src={item.logo} style={{ marginRight: "-40px", marginBottom: "10px", maxHeight: "80px" }} />
                        ))
                        }
                      </td>


                      <td>
                        {risultati.datiSpoglio[elezione].schedeValide.uninominale[candidato].coalizione.map((item, index) => (
                          <p style={{ borderBottom: "#e0e0e0 solid 1px", marginBottom: "0px" }} key={index}>{item.lista}</p>
                        ))
                        }
                      </td>

                      <td>
                        <p>{risultati.datiSpoglio[elezione].schedeValide.uninominale[candidato].voti && risultati.datiSpoglio[elezione].schedeValide.uninominale[candidato].voti.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                      </td>
                    </tr>
                  </>
                )}

              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export default Risultati

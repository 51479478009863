import React, { useEffect, useState } from "react"
import {
  Container,
  Modal,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import { useParams } from "react-router-dom"
import axios from "axios"
import { useHistory } from "react-router-dom"

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  deleteCommunication,
  modifyCommunication,
} from "../../helpers/fakebackend_helper"

const DettagliComunicazione = () => {
  const [communication, setCommunication] = useState({})
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [subtitle, setSubtitle] = useState("")

  let history = useHistory()
  let userInfo = JSON.parse(localStorage.getItem("user"))

  function tog_standard() {
    setmodal_standard(!modal_standard)
  }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }

  //opzioni data
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  
  let token = JSON.parse(localStorage.getItem('token'))

  let config = {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }

  const { id } = useParams()

  const data = {
    title: title,
    description: description,
    subtitle: subtitle,
  }

  const fetchDetails = () => {
    axios.get(`/comunications/${id}`, config)
    .then((response) => {
      setCommunication(response.data)
      setTitle(response.data.title)
      setSubtitle(response.data.subtitle)
      setDescription(response.data.description)
    })
  }

  const elimina = () => {
    deleteCommunication(id)
      .then(
        setTimeout(() => {
          history.push("/comunicazione")
        }, 3000)
      )
      .catch((err) => console.log(err))
  }

  const modifica = () => {
    modifyCommunication(id, data)
      .then(
        setTimeout(() => {
          history.push("/comunicazione")
        }, 3000)
      )
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Comunicazioni"
            breadcrumbItem="Dettagli Comunicazione"
          />

          <Card className="border border-primary">
            <div className="card-header bg-transparent border-primary">
              <h5 className="my-0 text-primary">
                <i className="mdi mdi-newspaper-variant-outline me-3"></i>
                {communication.title}
              </h5>
            </div>
            <CardBody>
              <h5 className="card-title">{communication.subtitle}</h5>
              <p className="card-text">{communication.description}</p>

              {communication.attachments && (
                <img
                  src={communication.attachments}
                  style={{ maxWidth: "-webkit-fill-available" }}
                  alt="immagine comunicazione"
                />
              )}

              {communication.updatedAt && (
                <p>
                  Ultimo aggiornamento: <span> </span>
                  {new Intl.DateTimeFormat("it-IT", options).format(
                    new Date(communication.updatedAt)
                  )}
                </p>
              )}

              {/* PULSANTE PER ELIMINARE COMUNICAZIONE, VISIBILE SOLO AL PARTITO */}
              {userInfo.role == "Partito Centrale" && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      tog_standard()
                    }}
                    className="btn btn-primary me-2"
                    data-toggle="modal"
                    data-target="#myModal">
                    Elimina <i className="mdi mdi-delete-empty me-1"></i>
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      tog_xlarge()
                    }}
                    className="btn btn-primary "
                    data-toggle="modal"
                    data-target="#myModal">
                    Modifica <i className="mdi mdi-lead-pencil me-1"></i>
                  </button>

                  <Modal
                    isOpen={modal_standard}
                    toggle={() => {
                      tog_standard()
                    }}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        Vuoi eliminare questo elemento?
                      </h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_standard(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Clicca su <strong>Elimina</strong> per eliminare
                        definitivamente questa comunicazione. <br />
                        Per annullare l'operazione, clicca su{" "}
                        <strong>Annulla</strong>.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_standard()
                        }}
                        className="btn btn-primary "
                        data-dismiss="modal">
                        Annulla
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => elimina(communication._id)}>
                        Elimina
                      </button>
                    </div>
                  </Modal>

                  <Modal
                    size="xl"
                    isOpen={modal_xlarge}
                    toggle={() => {
                      tog_xlarge()
                    }}>
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel">
                        Modifica Comunicazione
                      </h5>
                      <button
                        onClick={() => {
                          setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <input
                          value={title}
                          name="title"
                          type="text"
                          className="form-control"
                          id="formrow-title-input"
                          placeholder="Titolo"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </p>
                      <p>
                        <input
                          value={subtitle}
                          name="subtitle"
                          type="text"
                          className="form-control"
                          id="formrow-subtitle-input"
                          placeholder="Sottotitolo"
                          onChange={(e) => setSubtitle(e.target.value)}
                        />
                      </p>
                      <p className="mb-0">
                        <input
                          value={description}
                          name="description"
                          type="textarea"
                          className="form-control"
                          id="formrow-description-input"
                          placeholder="Testo"
                          onChange={(e) => {
                            setDescription(e.target.value)
                          }}
                        />
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          tog_xlarge()
                        }}
                        className="btn btn-primary "
                        data-dismiss="modal">
                        Annulla
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => modifica()}>
                        Modifica
                      </button>
                    </div>
                  </Modal>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DettagliComunicazione

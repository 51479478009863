import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/fdi.png"

import {
  getRegioni,
  getProvince,
  getComuni,
} from "../../helpers/fakebackend_helper"

const Register = (props) => {
  const [role, setRole] = useState("")
  const [regioni, setRegioni] = useState([])
  const [prov, setProv] = useState([])
  const [comuni, setComuni] = useState([])

  const dispatch = useDispatch()

  const { user, registrationError } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      role: "",
      regione: "",
      provincia: "",
      comune: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required(
        "Il campo è obbligatorio. Inserisci il tuo indirizzo email."
      ),
      firstName: Yup.string().required(
        "Il campo è obbligatorio. Inserisci il tuo nome."
      ),
      lastName: Yup.string().required(
        "Il campo è obbligatorio. Inserisci il tuo cognome."
      ),
      password: Yup.string()
        .required("Il campo è obbligatorio. Inserisci una password.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "La password deve contenere 8 caratteri di cui almeno un carattere maiuscolo, un numero e un carattere speciale"
        ),
    }),
    onSubmit: (values) => {
      console.log(values)
    },
  })

  // useEffect(() => {
  //   if (validation.values.regione) getProvince(validation.values.regione)
  // }, [validation.values.regione])

  // useEffect(() => {
  //   if (validation.values.provincia) getComuni(validation.values.provincia)
  // }, [validation.values.provincia])

  useEffect(() => {
    if (validation.values.role) {
      getRegioni({
        params: { role: validation.values.role },
      }).then((res) => setRegioni(res))
    }

    switch (validation.values.role) {
      case "Presidente Regionale":
        document.getElementById("provincia").style.display = "none"
        document.getElementById("comune").style.display = "none"
        break

      case "Coordinatore Provinciale":
        document.getElementById("provincia").style.display = "block"
        document.getElementById("comune").style.display = "none"
        break

      case "Coordinatore Comunale":
        document.getElementById("regione").style.display = "block"
        document.getElementById("provincia").style.display = "block"
        document.getElementById("comune").style.display = "block"
        break

      case "Rappresentante di Lista":
        document.getElementById("regione").style.display = "block"
        document.getElementById("provincia").style.display = "block"
        document.getElementById("comune").style.display = "block"
        break
    }
  }, [validation.values.role])
  
   const [showPassword, setShowPassword] = useState(false)

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0 justify-content-center">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="70" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Benvenuto!</h5>
                        <h6 className="mb-0">Compila il form e registrati</h6>
                      </div>

                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}>
                        {user && user
                          ? (
                              <Alert color="success">
                                Registrazione avvenuta con successo
                              </Alert>
                            ) &&
                            window.location.replace(
                              "/page-register-verification"
                            )
                          : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Inserisci Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 ">
                          <Label className="form-label">Nome</Label>
                          <Input
                            name="firstName"
                            type="text"
                            placeholder="Inserisci nome"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName || ""}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.firstName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Cognome</Label>
                          <Input
                            name="lastName"
                            type="text"
                            placeholder="Inserisci cognome"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName || ""}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.lastName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 ">
                          <Label className="form-label">Password</Label>
                          <div className="input-group  ">
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Inserisci Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}

                          <button onClick={(e) => {
                            e.preventDefault()
                            setShowPassword(!showPassword)
                          }} className="input-group-text text-dark">
                          </button>
                          </div>
                          
                        </div>

                        {/* ruolo */}
                        <div className="mb-3">
                          <Label className="form-label fs-5">
                            Seleziona un Ruolo:
                          </Label>

                          <label className="container">
                            <input
                              type="radio"
                              name="role"
                              value="Rappresentante di Lista"
                              onChange={validation.handleChange}
                              disabled={
                                !validation.values.email ||
                                !validation.values.firstName ||
                                !validation.values.lastName ||
                                !validation.values.password
                              }
                            />
                            <span className="mx-2 align-text-bottom">
                              Rappresentante di lista
                            </span>
                            <span className="checkmark"></span>
                          </label>
                          <label className="container">
                            <input
                              type="radio"
                              name="role"
                              value="Presidente Regionale"
                              onChange={validation.handleChange}
                              disabled={
                                !validation.values.email ||
                                !validation.values.firstName ||
                                !validation.values.lastName ||
                                !validation.values.password
                              }
                            />
                            <span className="mx-2 align-text-bottom">
                              Presidente regionale
                            </span>
                            <span className="checkmark"></span>
                          </label>
                          <label className="container ">
                            <input
                              type="radio"
                              name="role"
                              value="Coordinatore Provinciale"
                              onChange={validation.handleChange}
                              disabled={
                                !validation.values.email ||
                                !validation.values.firstName ||
                                !validation.values.lastName ||
                                !validation.values.password
                              }
                            />
                            <span className="mx-2 align-text-bottom">
                              Coordinatore provinciale
                            </span>
                            <span className="checkmark"></span>
                          </label>
                          <label className="container">
                            <input
                              type="radio"
                              name="role"
                              value="Coordinatore Comunale"
                              onChange={validation.handleChange}
                              disabled={
                                !validation.values.email ||
                                !validation.values.firstName ||
                                !validation.values.lastName ||
                                !validation.values.password
                              }
                            />
                            <span className="mx-2 align-text-bottom">
                              Coordinatore comunale
                            </span>
                            <span className="checkmark"></span>
                          </label>
                        </div>

                        {/* location - da rendere dinamici in base al ruolo scelto */}
                        {/* REGIONE */}
                        <div className="mb-3">
                          <select
                            key="reg"
                            id="regione"
                            className="form-select mb-3"
                            name="regione"
                            onBlur={validation.handleBlur}
                            value={validation.values.regione || ""}
                            defaultValue={"default"}
                            onChange={validation.handleChange}
                            disabled={
                              !validation.values.email ||
                              !validation.values.firstName ||
                              !validation.values.lastName ||
                              !validation.values.password ||
                              !validation.values.role
                            }>
                            <option
                              value={"default"}
                              label="Seleziona una regione"
                              key="regionedef"
                              hidden>
                              Seleziona una regione
                            </option>
                            {regioni.length > 0 &&
                              regioni.map((regione, i) => (
                                <option
                                  key={i}
                                  name={regione}
                                  onChange={validation.handleChange}
                                  label={regione.toUpperCase()}
                                  value={regione}>
                                  {regione}{" "}
                                </option>
                              ))}
                          </select>

                          {/* PROVINCIA */}
                          <select
                            key="prov"
                            id="provincia"
                            className="form-select mb-3"
                            name="provincia"
                            onBlur={validation.handleBlur}
                            value={validation.values.provincia || ""}
                            defaultValue={"default"}
                            onChange={validation.handleChange}
                            disabled={
                              !validation.values.email ||
                              !validation.values.firstName ||
                              !validation.values.lastName ||
                              !validation.values.password ||
                              !validation.values.role ||
                              !validation.values.regione
                            }>
                            <option
                              value={"default"}
                              label="Seleziona una provincia"
                              key="regionedef"
                              hidden>
                              Seleziona una provincia
                            </option>
                            {prov.length > 0 &&
                              prov.map((provincia, i) => (
                                <option
                                  key={i}
                                  name={provincia}
                                  onChange={validation.handleChange}
                                  label={provincia.toUpperCase()}
                                  value={provincia}>
                                  {provincia}{" "}
                                </option>
                              ))}
                          </select>

                          {/* COMUNE */}
                          <select
                            key="com"
                            id="comune"
                            className="form-select mb-3"
                            name="comune"
                            onBlur={validation.handleBlur}
                            value={validation.values.comune || ""}
                            defaultValue={"default"}
                            onChange={validation.handleChange}
                            disabled={
                              !validation.values.email ||
                              !validation.values.firstName ||
                              !validation.values.lastName ||
                              !validation.values.password ||
                              !validation.values.role ||
                              !validation.values.regione ||
                              !validation.values.provincia
                            }>
                            <option
                              value={"default"}
                              label="Seleziona un comune"
                              key="regionedef"
                              hidden>
                              Seleziona un comune
                            </option>
                            {comuni.length > 0 &&
                              comuni.map((comune, i) => (
                                <option
                                  key={i}
                                  name={comune}
                                  onChange={validation.handleChange}
                                  label={comune.toUpperCase()}
                                  value={comune}>
                                  {comune}{" "}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit">
                            Registrati
                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Hai già un account?
                          <Link
                            to="/login"
                            className="text-primary fw-semibold">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register

import React, { useState, useEffect } from "react"
import RegistrationForm from "../../components/Common/RegistrationForm"
import { getComuni, getProvince, getRegioni, postSignup } from "../../helpers/fakebackend_helper"
import { toast } from "react-toastify"

export default function RegisterCoordinatoreComunale() {
  const [regioni, setRegioni] = useState(null)
  const [province, setProvince] = useState(null)
  const [comuni, setComuni] = useState(null)

  const [regione, setRegione] = useState("")
  const [provincia, setProvincia] = useState("")
  const [comune, setComune] = useState("")

  const role = "Coordinatore Comunale"

  const handleOnSubmit = (inputsData) => {
    if (regione && provincia) {
      postSignup({ ...inputsData, role, regione, provincia, comune })
        .then(() => window.location.replace("/page-register-verification"))
        .catch((err) => toast.error(err.response.data.message))
    }
  }
  const handleFetchRegioni = () => {
    getRegioni()
      .then((res) => setRegioni(res))
      .catch((err) => console.log(err))
  }

  const handleFetchProvince = (regione) => {
    getProvince({ params: { regione, role } })
      .then((res) => setProvince(res))
      .catch((err) => console.log(err))
  }

  const handleFetchComuni = (provincia) => {
    getComuni({ params: { provincia, role } })
      .then((res) => { setComuni(res)})
      .catch((err) => console.log(err))
  }

  useEffect(() => handleFetchRegioni(), [])
  useEffect(() => {if (regione) handleFetchProvince(regione)}, [regione])
  useEffect(() => {if (regione && provincia) handleFetchComuni(provincia)}, [provincia])


  return <>
   <RegistrationForm
        handleOnSubmit={handleOnSubmit}
        roleForm={
          <>
            <div className="mb-3" style={{ marginTop: "40px" }}>
              {/* <h6>Seleziona la tua area di appartenenza</h6> */}
              <select
                key="reg"
                id="regione"
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setRegione(e.target.value)}>
                <option value="">Seleziona la tua regione</option>
                {regioni &&
                  regioni.map((regione) => (
                    <option key={regione} value={regione}>
                      {regione}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-3">
              <select
                key="prov"
                id="provincia"
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setProvincia(e.target.value)}>
                <option value="">Seleziona la tua provincia</option>
                {province &&
                  province.map((provincia) => (
                    <option key={provincia} value={provincia}>
                      {provincia}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3">
              <select
                key="com"
                id="comune"
                className="form-select mb-3"
                name="comune"
                defaultValue={"default"}
                disabled={!provincia}
                onChange={(e) => setComune(e.target.value)}>
                <option
                  value="default"
                  label="Seleziona un comune"
                  key="comunedef"
                  hidden></option>
                {comuni &&
                  comuni.map((comune, i) => (
                    <option
                      key={i}
                      name={comune}
                      label={comune.toUpperCase()}
                      value={comune}>
                      {comune}
                    </option>
                  ))}
              </select>
            </div>
          </>
        }
      />
  </>
}

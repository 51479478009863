import axios from "axios"
import { del, get, post, postFormData, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

//finta api
export const getDati = (params) => get(url.GET_DATI, params)

export const getListe = (params) => get(url.GET_LISTE, params)

// Inserimento dati spoglio
export const getListePerSezione = (params) => get(url.GET_LISTE_PER_SEZIONE, params)

export const postSchedeBianche = (params, data) => post(url.SCHEDE_BIANCHE, data, params)

export const postSchedeNulle = (params, data) => post(url.SCHEDE_NULLE, data, params)

export const postAffluenza = (data) => post(url.AFFLUENZA, data)

export const postTotaleElettori = (data) => post(url.TOTALE_ELETTORI, data)

export const getRisultati = (params) => get(url.GET_RISULTATI, { params })

export const getDatiSezione = (params) => get(url.GET_DATI_SEZIONE, params)

// Dati spoglio

export const getRegioniSpoglio = () => get(url.GET_ELENCO_REGIONI)

export const getComuniSpoglio = (params) => get(url.GET_ELENCO_COMUNI, { params })

export const getSezioniSpoglio = (params) => get(url.GET_ELENCO_SEZIONI, { params })

export const getCollegiSpoglio = (params) => get(url.GET_ELENCO_COLLEGI, { params })

export const postRisultati = (data, params) => post(url.POST_RISULTATI, data, params)

// Register Method
const postSignup = (data) => post(url.SIGNUP, data)

// Login Method
const postLogin = (data) => post(url.LOGIN, data)

export const subscribeUser = (data) => post(url.SUBSCRIBE_USER, data)

//Login OTP
const loginOtp = (data) => post(url.LOGINOTP, data)

// postForgetPwd
const postFakeForgetPwd = (data) => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = (data) => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = (data) => post(url.POST_EDIT_PROFILE, data)

export const getSezione = (params) => get(url.GETSEZIONI, params)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch((err) => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}
//comunicazioni
export const getCommunications = () => get(url.NEW_COMMUNICATION)

export const newCommunication = (data) => post(url.NEW_COMMUNICATION, data)

export const deleteCommunication = (id) => del(`${url.NEW_COMMUNICATION}/${id}`)

export const modifyCommunication = (id, data) =>
  put(`${url.NEW_COMMUNICATION}/${id}`, data)

//segnalazioni criticità
export const newIssue = (data) => postFormData(url.NEW_ISSUE, data)

export const getIssues = () => get(url.GET_ISSUES)

export const getDetailsIssue = (id) => get(`${url.GET_DETAILS_ISSUE}/${id}`)

export const deleteIssue = (id) => del(`${url.DELETE_ISSUE}/${id}`)

export const changeStatus = (id, data) =>
  put(`${url.CHANGE_STATUS_ISSUE}/${id}`, data)

//utenti
export const fetchAllUsers = (data) =>
  post(url.GET_ALL_USERS, data)

export const fetchListaUtentiInBaseAlRuolo = () => get(url.GET_USERS_ROLE_BASED)
  
export const getUsersInPending = () => get(url.GET_USERS_IN_PENDING)

export const changeSezioni = (data) => put(url.CHANGE_SEZIONI, data)

export const approveUser = (data) => put(url.APPROVE_USER, data)

export const refuseUser = (data) => put(url.REFUSE_USER, data)

export const ModificaUtente = (data) => put(url.MODIFICA_UTENTE, data)

export const getRegioni = (params) => get(url.GET_REGIONI, params)

export const getProvince = (params) => get(url.GET_PROVINCE, params)

export const getComuni = (params) => get(url.GET_COMUNI, params)

export const getSezioni = (params) => get(url.GET_SEZIONI, params)

export const postNewUser = (data) => post(url.POST_NEW_USER, data)

export const getResetPassword = (params) => get(url.GET_RESET_PASSWORD, params)

export const postNewPassword = (data, config) =>
  post(url.POST_CHANGE_PASSWORD, data, config)

// Login Method
const postJwtLogin = (data) => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = (data) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = (data) => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = (id) =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = (event) => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = (event) => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = (event) =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = (message) => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = (order) => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = (order) => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = (order) =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = (customer) => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = (customer) => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = (customer) =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = (id) =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = (id) =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = (user) => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = (user) => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = (user) => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = (project) => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = (project) => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = (project) =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = (inboxmail) =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = (inboxmail) =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = (month) =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = (month) =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postSignup,
  postLogin,
  loginOtp,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,

}

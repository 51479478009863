import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

function PaginationCustom({currentPage, setPage, totalPages}) {
  
  const handlePagination = (page) => {
    setPage( page )
  }

  // Numero di pagine provvisorio
  const totPages = new Array(totalPages).fill(1)

  return <Pagination aria-label="Page navigation example" style={{flexWrap: 'wrap'}}>
          
          <PaginationItem disabled={currentPage === 1 && true}>
            <PaginationLink previous onClick={() => setPage(prevState => prevState - 1)}/>
          </PaginationItem>

          {
            totPages.map((el, idx) => (            
              <PaginationItem key={idx} active={currentPage === idx + 1 && true} >
                <PaginationLink onClick={() => handlePagination( idx + 1 )}>
                  {idx + 1}
                </PaginationLink>
              </PaginationItem>
            ))
          }

          <PaginationItem disabled={totPages.length === currentPage && true}>
            <PaginationLink next onClick={() => setPage(prevState => prevState + 1)}/>
          </PaginationItem>

        </Pagination>
}

export default PaginationCustom